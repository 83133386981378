import React, { useEffect } from "react";
import Sidebar from "../../components/panel/sidebar/Sidebar";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Profile from "../../components/panel/profile/Profile";
import Orders from "../../components/panel/sellings/orders/Orders";
import Wallet from "../../components/panel/wallet/Wallet";
import Earnings from "../../components/panel/statistics/StatsComp/Earnings";
import OrdersStats from "../../components/panel/statistics/StatsComp/OrdersStats";
import ReportsTable from "../../components/panel/statistics/StatsComp/ReportsTable";
import ControlPanelPipe from "../../components/panel/control panel/ControlPanelPipe";
import Order from "../../components/panel/order/Order";
import Warehouses from "../../components/panel/warehouse/warehouses/Warehouses";
import ProductsStock from "../../components/panel/warehouse/products-stock/ProductsStock";
import VariantsStock from "../../components/panel/warehouse/variants-stock/PaymentRequests";
import Suppliers from "../../components/panel/purchases/suppliers/Suppliers";
import VariantsHistory from "../../components/panel/purchases/variants-stocks-history/VariantsHistory";
import Purchases from "../../components/panel/purchases/Purchases";
import Favorites from "../../components/panel/products/Favorites/Favorites";
import MyStores from "../../components/panel/store/MyStores";
import CreateLandingpage from "../../components/panel/store/CreateLandingpage";
import MyLandpages from "../../components/panel/store/MyLandpages";
import AddProduct from "../../components/panel/products/add product/AddProduct";
import PaymentRequests from "../../components/panel/payment requests/PaymentRequests";
import Users from "../../components/panel/users/Users";
import ProfilePreview from "../../components/panel/user/ProfilePreview";
import ProductStockDetails from "../../components/panel/warehouse/products-stock/ProductStockDetails";
import NavigateToRoot from "../../components/authentication/NavigateToRoot";
import Last30Orders from "../../components/panel/statistics/StatsComp/EarningsComponents/Last30Orders";
import ProductsPipe from "../../components/panel/products/ProductsPipe";
import Product from "../../components/panel/product/Product";
import PickupReport from "../../components/panel/warehouse/warehouses-reports/pickup report/PaymentRequests";
import CouriersCOD from "../../components/panel/sellings/couriers excel/PaymentRequests";
import OrdersContent from "../../components/panel/sellings/orders content excel/PaymentRequests";
import Shipping from "../../components/panel/shipping/Shipping";
import Courier from "../../components/panel/shipping/courier/Shipping";
import Exclusive from "../../components/panel/products/exclusive/Shipping";
import ExclusiveAffiliate from "../../components/panel/products/exclusive affiliate/Favorites";
import EasyOrdersTabs from "../../components/panel/outside integrations/EasyOrdersTabs";
import "./res.css";
import Banners from "../../components/panel/settings/banners/Banners";
import Balances from "../../components/panel/transactions/balances/Shipping";
import IncomeTabs from "../../components/panel/transactions/income/EasyOrdersTabs";
import ExpenseTabs from "../../components/panel/transactions/expense/EasyOrdersTabs";
import Couriers from "../../components/panel/statistics/admin/Couriers";
import ExpensesReports from "../../components/panel/statistics/admin/Expenses";
import EarningsReports from "../../components/panel/statistics/admin/Earnings";
import TransactionTabs from "../../components/panel/payment requests/Transaction/TransactionTabs";
import OrdersBulk from "../../components/panel/sellings/orders bulk/OrdersBulk";
import Govs from "../../components/panel/statistics/admin/Govs";
import AllOrders from "../../components/panel/statistics/admin/AllOrders";
import SellersOverview from "../../components/panel/statistics/admin/SellersOverview";
import ProductsOverview from "../../components/panel/statistics/admin/ProductsOverview";
import MostFrequentBuyers from "../../components/panel/statistics/admin/MostFrequentBuyers";
import OrderFulfillment from "../../components/panel/order fulfillment/Order";
import ShippingSellerFulfillment from "../../components/panel/shipping/shipping fulfillment/Shipping";
import Levels from "../../components/panel/settings/levels/Levels";
import FulfilllmentRepost from "../../components/panel/statistics/StatsComp/FulfilllmentRepost";
import OrdersBulkFulfillment from "../../components/panel/sellings/orders bulk fulfillment/OrdersBulk";
import OrdersSettlements from "../../components/panel/sellings/orders/settlements/Shipping";
import Sizes from "../../components/panel/products/sizes/Sizes";
import Colors from "../../components/panel/products/colors/Colors";
import AboutShipping from "../../components/panel/sellings/about shipping/AboutShipping";
import MarketerOverview from "../../components/panel/statistics/admin/MarketerOverview";
import ConfirmationReport from "../../components/panel/statistics/StatsComp/ConfirmationReport";
import PaymentRequest from "../../components/panel/statistics/admin/payment request/PaymentRequest";
import OrderByDay from "../../components/panel/statistics/admin/OrderByDay";
import DownloadManifest from "../../components/panel/sellings/download manifest/DownloadManifest";
import Products from "../../components/panel/products/offers_marketer/Products";
import Offers from "../../components/panel/products/offers/Offers";
import Offer from "../../components/panel/products/offer/Offers";
import StagnantProducts from "../../components/panel/statistics/admin/StagnantProducts";
import FinanceSales from "../../components/panel/sales/FinanceSales";
import { CreateStore } from "../../components/panel/builder/stores/steps/CreateStore";
import Pixel from "../../components/panel/store/Pixel";
import OffersWholesale from "../../components/panel/products/offers_wholesale/Offers";
import WholeSaleMarketer from "../../components/panel/products/wholesale_marketer/Products";
import ShippingPage from "../../components/panel/shipping/ShippingPage";
import FinanceCouriers from "../../components/panel/sales/FinanceCouriers";
import FinanceMarketers from "../../components/panel/sales/FinanceMarketers";
import FinanceSellers from "../../components/panel/sales/FinanceSellers";
import FinanceSellerDetails from "../../components/panel/sales/FinanceSellerDetails";
import Category from "../../components/panel/products/category/Category";
import FinanceSuppliers from "../../components/panel/sales/FinanceSuppliers";
import FinanceSuppliersDetails from "../../components/panel/sales/FinanceSuppliersDetails";
import LandingPageAnalysis from "../../components/panel/store/LandingPageAnalysis";
import LandingPageOverview from "../../components/panel/store/LandingPageOverview";
import LandingPageOffers from "../../components/panel/store/LandingPageOffers";
import YallaShopTabs from "../../components/panel/outside integrations/yallaShop/YallaTabs";
import FinanceMarketerDetails from "../../components/panel/sales/FinanceMarketerDetails";
import ProductDiscounts from "../../components/panel/products/product discounts/ProductDiscounts";
import Currency from "../../components/panel/settings/currency/Currency";
import AllowedRole from "../../components/panel/settings/currency/allowed role/AllowedRole";
import { AffiliateSuggestionAdmin } from "../../components/panel/contact us/affiliate suggestion/AffiliateSuggestionAdmin";
import { AffiliateSuggestionAdminWithId } from "../../components/panel/contact us/affiliate suggestion/AffiliateSuggestionAdminWithId";
import ContactUs from "../../components/panel/contact us/contactus/ContactUs";
import ContactUsWithId from "../../components/panel/contact us/contactus/ContactUsWithId";
import { AffiliateSuggestionForm } from "../../components/panel/contact us/affiliate suggestion/AffiliateSuggestionForm";
import Fulfillment from "../../components/panel/users/fulfillment/Shipping";
 
const Panel = () => {
  const { lang } = useParams();
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  useEffect(() => {
    if (token) {
    } else {
      Navigate(`/ar/auth/login`);
    }
  }, [token, Navigate]);
  return (
    <Container className="menu-mobil-height ">
      <div className="d-flex my-4 gap-3" dir={lang === "en" ? "ltr" : "rtl"}>
        <Sidebar />
        <div className="res-container">
          <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/sellings/" element={<Orders />} />
            {role != "fulfillment" ? (
              <Route path="/sellings/orders-bulk" element={<OrdersBulk />} />
            ) : (
              <Route
                path="/sellings/orders-bulk-fulfillment"
                element={<OrdersBulkFulfillment />}
              />
            )}
            <Route
              path="/sellings/orders-settlements"
              element={<OrdersSettlements />}
            />

            <Route path="/sellings/postponed-orders" element={<Orders />} />
            <Route
              path="/sellings/download-manifest"
              element={<DownloadManifest />}
            />
            <Route path="/sellings/shipping-excel" element={<CouriersCOD />} />
            <Route path="/sellings/cancels" element={<OrdersContent />} />

            <Route path="/warehouse/warehouses" element={<Warehouses />} />
            <Route
              path="/warehouse"
              element={role == "admin" ? <Warehouses /> : <ProductsStock />}
            />
            <Route path="/warehouse/suppliers" element={<Suppliers />} />
            <Route
              path="/warehouse/variants-history"
              element={<VariantsHistory />}
            />

            <Route path="/purchases" element={<Purchases />} />
            <Route path="/purchases/suppliers" element={<Suppliers />} />
            <Route
              path="/purchases/variants-history"
              element={<VariantsHistory />}
            />
            <Route
              path="/warehouse/products-stock"
              element={<ProductsStock />}
            />
            <Route
              path="/warehouse/product-stock/:productId"
              element={<ProductStockDetails />}
            />

            <Route
              path="/warehouse/variants-stock"
              element={<VariantsStock />}
            />

            <Route path="/sellings/pickup-report" element={<PickupReport />} />

            <Route path="/sellings/orders" element={<Orders />} />
            <Route
              path="/sellings/about-shipping"
              element={<AboutShipping />}
            />

            <Route path="/shipping" element={<Shipping />} />
            <Route
              path="/shipping/courier/pricing/:courierId"
              element={<Courier />}
            />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/settings/couriers" element={<ShippingPage />} />
            <Route path="/settings/fulfillment" element={<Fulfillment />} />
            <Route path="/settings/countries" element={<Currency />} />
            <Route path="/settings/countries/:currencyId" element={<AllowedRole />} />
            <Route
              path="/shipping/courier/pricing/:courierId"
              element={<Courier />}
            />
            <Route path="/shipping/prices" element={<Shipping />} />

            <Route path="/control-panel" element={<ControlPanelPipe />} />

            <Route path="/" element={<ControlPanelPipe />} />

            <Route path="outside-integrations" element={<></>} />
            <Route
              path="outside-integrations/easy-orders"
              element={<EasyOrdersTabs />}
            />

            <Route
              path="outside-integrations/yalla-shop"
              element={<YallaShopTabs />}
            />
            <Route
              path="/statistics"
              element={
                role == "admin" ||
                (role == "employee" &&
                  employee_type == "operation employee") ? (
                  <Couriers />
                ) : (
                  <Earnings />
                )
              }
            />
            <Route path="/statistics/earnings" element={<Earnings />} />
            <Route
              path="/statistics/earnings-reports"
              element={<EarningsReports />}
            />
            <Route
              path="/statistics/fulfillment-report"
              element={<FulfilllmentRepost />}
            />
            <Route
              path="/statistics/confirmation-report"
              element={<ConfirmationReport />}
            />
            <Route
              path="/statistics/expenses-reports"
              element={<ExpensesReports />}
            />
            <Route
              path="/statistics/orders-statuses"
              element={<OrdersStats />}
            />
            <Route path="/statistics/products" element={<ReportsTable />} />
            <Route path="/statistics/orders" element={<Last30Orders />} />
            <Route path="/statistics/couriers" element={<Couriers />} />
            <Route path="/statistics/govs" element={<Govs />} />
            <Route path="/statistics/all-orders" element={<AllOrders />} />
            <Route
              path="/statistics/sellers-overview"
              element={<SellersOverview />}
            />
            <Route
              path="/statistics/payment-request"
              element={<PaymentRequest />}
            />
            <Route path="/statistics/order-by-day" element={<OrderByDay />} />
            <Route
              path="/statistics/products-overview"
              element={<ProductsOverview />}
            />
            <Route
              path="/statistics/stagnant-products"
              element={<StagnantProducts />}
            />
            <Route
              path="/statistics/marketer-overview"
              element={<MarketerOverview />}
            />
            <Route
              path="/statistics/most-frequent-buyers"
              element={<MostFrequentBuyers />}
            />
            <Route path="/statistics" element={<MostFrequentBuyers />} />

            <Route path="/products" element={<ProductsPipe />} />
            <Route path="/products/all-products" element={<ProductsPipe />} />
            <Route path="/products/add-product" element={<AddProduct />} />
            <Route path="/products/category" element={<Category />} />
            <Route path="/products/all-favorites" element={<Favorites />} />
            <Route path="/products/sizes" element={<Sizes />} />
            <Route path="/products/colors" element={<Colors />} />

            <Route path="/products/offers" element={<Offers />} />
            <Route path="/products/all-offers" element={<Products />} />

            <Route path="/products/wholesale" element={<OffersWholesale />} />
            <Route
              path="/products/all-wholesale"
              element={<WholeSaleMarketer />}
            />

            <Route path="/products/offer/:id" element={<Offer />} />
            <Route
              path="/products/exclusive-products"
              element={<Exclusive />}
            />
            <Route
              path="/products/prodcuts-exclusive"
              element={<ExclusiveAffiliate />}
            />
            <Route path="/products" element={<ProductsPipe />} />
            <Route path="/products/all-products" element={<ProductsPipe />} />
            <Route path="/products/add-product" element={<AddProduct />} />
            <Route path="/products/all-favorites" element={<Favorites />} />
            <Route path="/contact-us/affiliate-suggestion" element={<AffiliateSuggestionForm />} />
            <Route path="/contact-us/affiliate-suggestion-admin" element={<AffiliateSuggestionAdmin />} />
            <Route path="/contact-us/affiliate-suggestion-admin/:id" element={<AffiliateSuggestionAdminWithId />} />

            <Route path="/product/:productId" element={<Product />} />
            <Route path="/products/product-discounts" element={<ProductDiscounts />} />

            <Route path="/my-shop/create-store" element={<CreateStore />} />
            <Route path="/my-shop/my-shops" element={<MyStores />} />
            <Route path="/my-shop" element={<MyStores />} />
            <Route
              path="/my-shop/create-landpage"
              element={<CreateLandingpage />}
            />
            <Route path="/my-shop/offers/:id" element={<LandingPageOffers />} />
            <Route
              path="/my-shop/landing-page/analysis"
              element={<LandingPageAnalysis />}
            />
            <Route
              path="/my-shop/landing-page/overview"
              element={<LandingPageOverview />}
            />
            <Route path="/my-shop/my-landpages" element={<MyLandpages />} />

            <Route path="/my-shop/pixel" element={<Pixel />} />

            <Route path="/wallet" element={<Wallet />} />
            <Route path="/order/:orderId" element={<Order />} />
            <Route
              path="/order/fulfillment/:orderId"
              element={<OrderFulfillment />}
            />
            <Route path="/transactions" element={<PaymentRequests />} />
            <Route
              path="/transactions/payment-requests"
              element={<PaymentRequests />}
            />
            <Route path="/transactions/income" element={<IncomeTabs />} />
            <Route path="/transactions/expenses" element={<ExpenseTabs />} />

            <Route path="/transactions/balances" element={<Balances />} />
            <Route path="/transaction/:id" element={<TransactionTabs />} />
            <Route path="/users" element={<Users />} />
            <Route path="/financials/sales" element={<FinanceSales />} />
            <Route
              path="/financials/couriers-overview"
              element={<FinanceCouriers />}
            />
            <Route
              path="/financials/marketers-overview"
              element={<FinanceMarketers />}
            />
            <Route
              path="/financials/sellers-overview"
              element={<FinanceSellers />}
            />
            <Route
              path="/financials/suppliers-overview"
              element={<FinanceSuppliers />}
            />
            <Route
              path="/financials/supplier-details/:id"
              element={<FinanceSuppliersDetails />}
            />
            <Route
              path="/financials/sellers-details/:id"
              element={<FinanceSellerDetails />}
            />
            <Route
              path="/financials/marketer-details/:id"
              element={<FinanceMarketerDetails />}
            />

            <Route
              path="/shipping/fulfillment/govs/:userId"
              element={<ShippingSellerFulfillment />}
            />
            <Route path="/user/:userId" element={<ProfilePreview />} />
            <Route path="settings" element={<Banners />} />
            <Route path="contact-us/messages/:id" element={<ContactUsWithId />} />
            <Route path="settings/levels" element={<Levels />} />
            <Route
              path="contact-us/messages"
              element={<ContactUs />}
            />
            <Route path="settings/banners" element={<Banners />} />
            <Route path="*" element={<NavigateToRoot />} />
          </Routes>
        </div>
      </div>
    </Container>
  );
};

export default Panel;
