import React, { useContext } from "react";
import "./Sidebar.css";
import IconsGen from "../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import Statistics from "./Links/Statistics";
import Roles from "../../authentication/Roles";
import Wallet from "./Links/Wallet";
import ProtectedSidebarRoute from "../../authentication/ProtectedSidebarRoute";
import Orders from "./Links/Orders";
import Products from "./Links/Products";
import MyShop from "./Links/MyShop";
import ControlPanel from "./Links/ControlPanel";
import Warehouse from "./Links/Warehouse";
import PaymentReq from "./Links/PaymentReq";
import EmployeeTypes from "../../authentication/EmployeeTypes";
import Users from "./Links/Users";
import LinkOutside from "./Links/LinkOutside";
import Settings from "./Links/Settings";
import Purchases from "./Links/Purchases";
import Sales from "./Links/Sales";
import { UserContext } from "../../contexts/UserContext";
import ContactUs from "./Links/ContactUs";
import { FaWhatsapp } from "react-icons/fa";

const Sidebar = () => {
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const ecombo_affiliate = localStorage.getItem("ecombo_affiliate");
  const { userData } = useContext(UserContext);

  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const direction = lang === "en" ? "rtl" : "ltr";
  return (
    <aside className="px-2 py-3 d-none d-md-flex gap-3 flex-column">
      <ProtectedSidebarRoute
        component={ControlPanel}
        role={role}
        roles={[
          Roles["AFFILIATE"],
          Roles["SELLER"],
          Roles["ADMIN"],
          Roles["FULFILLMENT"],
        ]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={ControlPanel}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[
          EmployeeTypes["CONFIRMATION_EMPLOYEE"],
          EmployeeTypes["OPERATION_EMPLOYEE"],
        ]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={Statistics}
        role={role}
        roles={[
          Roles["AFFILIATE"],
          Roles["SELLER"],
          Roles["FULFILLMENT"],
          Roles["ADMIN"],
        ]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={Statistics}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[EmployeeTypes["OPERATION_EMPLOYEE"]]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={Sales}
        role={role}
        roles={[Roles["ADMIN"]]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={Wallet}
        role={role}
        roles={[Roles["AFFILIATE"], Roles["SELLER"], Roles["FULFILLMENT"]]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={Orders}
        role={role}
        roles={[
          Roles["AFFILIATE"],
          Roles["SELLER"],
          Roles["ADMIN"],
          Roles["FULFILLMENT"],
        ]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={Orders}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[
          EmployeeTypes["CONFIRMATION_EMPLOYEE"],
          EmployeeTypes["FINANCIALS_EMPLOYEE"],
          EmployeeTypes["OPERATION_EMPLOYEE"],
        ]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={Products}
        role={role}
        roles={[
          Roles["AFFILIATE"],
          Roles["EMPLOYEE"],
          Roles["SELLER"],
          Roles["ADMIN"],
          Roles["FULFILLMENT"],
        ]}
        direction={direction}
      />
      {/* {(ecombo_affiliate || userData?.id === 12768 || userData?.id === 3) && ( */}
      <ProtectedSidebarRoute
        component={MyShop}
        role={role}
        roles={[Roles["AFFILIATE"]]}
        direction={direction}
      />
      {/* )} */}

      <ProtectedSidebarRoute
        component={Warehouse}
        role={role}
        roles={[Roles["ADMIN"], Roles["SELLER"], Roles["FULFILLMENT"]]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={Purchases}
        role={role}
        roles={[Roles["ADMIN"]]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={Purchases}
        role={role}
        empType={employee_type}
        roles={[Roles["EMPLOYEE"]]}
        empTypes={[EmployeeTypes["WAREHOUSE_EMPLOYEE"]]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={Warehouse}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[
          EmployeeTypes["FINANCIALS_EMPLOYEE"],
          EmployeeTypes["WAREHOUSE_EMPLOYEE"],
          EmployeeTypes["OPERATION_EMPLOYEE"],
        ]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={PaymentReq}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[EmployeeTypes["FINANCIALS_EMPLOYEE"]]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={PaymentReq}
        role={role}
        roles={[Roles["ADMIN"]]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={Users}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[
          EmployeeTypes["FINANCIALS_EMPLOYEE"],
          EmployeeTypes["OPERATION_EMPLOYEE"],
          EmployeeTypes["PRODUCTS_EMPLOYEE"],
        ]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={Users}
        role={role}
        roles={[Roles["ADMIN"]]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={Settings}
        role={role}
        roles={[Roles["ADMIN"]]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={ContactUs}
        role={role}
        roles={[Roles["ADMIN"]]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={ContactUs}
        role={role}
        roles={[Roles["AFFILIATE"]]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={ContactUs}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[
          EmployeeTypes["PRODUCTS_EMPLOYEE"],
          EmployeeTypes["OPERATION_EMPLOYEE"],
        ]}
        direction={direction}
      />

      <ProtectedSidebarRoute
        component={LinkOutside}
        role={role}
        roles={[Roles["AFFILIATE"]]}
        direction={direction}
      />

      <div className="links-container d-flex flex-column">
        <NavLink
          to={`/${slug}/${lang}/panel/profile`}
          className={`feature_link ${
            lang === "en" ? "feature_link_en" : ""
          } d-flex flex-row-reverse align-items-center gap-2 py-3`}
          dir={direction}
        >
          <IconsGen icon_name={"profile"} />
          <p>{_("panel.myprofile")}</p>
        </NavLink>
      </div>
      {role === "affiliate" && (
        <a
          href="https://wa.me/00971565858882"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-success gap-2 d-flex align-items-center rounded-pill px-4 py-2"
        >
          <div
            className="bg-dark text-white rounded-circle d-flex align-items-center justify-content-center me-2"
            style={{ width: "40px", height: "40px" }}
          >
            <FaWhatsapp size={20} />
          </div>
          <span className="fw-bold"> {_("panel.contactUs")}</span>
        </a>
      )}
    </aside>
  );
};

export default Sidebar;
