import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Marketers = ({ allData }) => {
  const { lang } = useParams();
  const [_] = useTranslation("global");

  // State to keep track of the sort configuration
  const [sortConfig, setSortConfig] = useState({
    key: 'approved_payment_requests', // Default sort by approved_payment_requests
    direction: 'descending', // Default direction
  });

  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  // Function to format amount
  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString();
  };

  // Function to handle column sorting
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Function to get sort indicator
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
    }
    return ' 🔽'; // Default indicator
  };

  // Memoized sorted data based on the sort configuration
  const sortedData = React.useMemo(() => {
    let sortableItems = [...allData];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Convert string values to numbers for sorting if necessary
        if (!isNaN(aValue) && !isNaN(bValue)) {
          aValue = Number(aValue);
          bValue = Number(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [allData, sortConfig]);

  return (
    <div className="row" dir={box_head_dir} style={{ height: '1200px' }}>
      <div
        className="col-12"
        style={{ position: 'relative', overflowX: 'auto', height: '100%' }}
      >
        <Table
          responsive
          dir={box_head_dir}
          className="reports-table text-nowrap text-center"
        >
          <thead>
            <tr className="text-center">
              <th onClick={() => handleSort('id')} style={{ cursor: 'pointer' }}>
                {_("home.fullname")}
                {getSortIndicator('id')}
              </th>
              <th onClick={() => handleSort('balance')} style={{ cursor: 'pointer' }}>
                {_("panel.balance")}
                {getSortIndicator('balance')}
              </th>
              <th onClick={() => handleSort('confirmed_money')} style={{ cursor: 'pointer' }}>
                {_("panel.confirmedMoney")}
                {getSortIndicator('confirmed_money')}
              </th>
              <th onClick={() => handleSort('expected_money')} style={{ cursor: 'pointer' }}>
                {_("panel.expectedMoney")}
                {getSortIndicator('expected_money')}
              </th>
              <th onClick={() => handleSort('approved_payment_requests')} style={{ cursor: 'pointer' }}>
                {_("panel.approvedPaymentRequests")}
                {getSortIndicator('approved_payment_requests')}
              </th>
              <th onClick={() => handleSort('sys_commission')} style={{ cursor: 'pointer' }}>
                {_("panel.sys_commission")}
                {getSortIndicator('sys_commission')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, index) => (
              <tr key={index} className="text-center">
                <td>
                  <span className="fw-bold">#{item.id}</span><br />
                  {item.first_name} {item.last_name}
                </td>
                <td>{formatAmount(item?.balance)}</td>
                <td>{formatAmount(item?.confirmed_money)}</td>
                <td>{formatAmount(item?.expected_money)}</td>
                <td>{formatAmount(item?.approved_payment_requests)}</td>
                <td>{formatAmount(item?.sys_commission)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Marketers;
