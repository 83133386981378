import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import BaseServerUrl from "../../../authentication/BaseServerUrl";
import { Image } from "primereact/image";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import TableSelectStatus from "../TableSelectStatus";
import ConfirmModal from "./ConfirmModal";
import { notifyError, notifySuccess } from "../../../toastify/toastify";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const paymentArabicEng = {
  محفظة: "wallet",
  "حواله بنكية": "bank_transfer",
  "باي بال": "paypal",
  "تيك توك": "tiktok",
  "فيزا ايكومبو": "ecombo",
  USDT: "USDT",
  "": "",
  وايز: "wise",
};
const paymentRequestTypes = {
  wallet: {
    accountNumber: true,
    email: false,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "محفظة",
  },
  wise: {
    accountNumber: false,
    email: true,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "وايز",
  },
  bank_transfer: {
    accountNumber: true,
    email: false,
    bank_name: true,
    bank_country: true,
    bank_account_currency: true,
    bank_account_holder_name: true,
    IBAN: true,
    arabic: "حواله بنكية",
  },
  paypal: {
    accountNumber: false,
    email: true,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "باي بال",
  },
  tiktok: {
    accountNumber: false,
    email: false,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "تيك توك",
  },
  ecombo: {
    accountNumber: false,
    email: false,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "فيزا ايكومبو",
  },
  USDT: {
    accountNumber: true,
    email: false,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "USDT",
  },
  "": {},
};

const change_order_status = (
  slug,
  lang,
  token,
  orderId,
  status,
  handleStatusChangeErr,
  bank,
  handleLoader
) => {
  handleLoader(true);

  AxiosController.put(
    `api/${slug}/${lang}/change-request-status/${orderId}`,
    { status, account_id: status === "Rejected" ? 1 : bank },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleLoader(false);
      notifySuccess(res.data.message);
    })
    .catch((e) => {
      handleStatusChangeErr(true);

      notifyError(e.response.data.message);

      handleLoader(false);
    });
};
const accounts = (slug, lang, token, shippingcostsHandler) => {
  AxiosController.get(`api/${slug}/${lang}/accounts/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      shippingcostsHandler(res.data);
    })
    .catch((e) => {});
};
const Transaction = () => {
  const { slug, lang, id } = useParams();
  const pond = useRef(null);
  const [files, setFiles] = useState([]);

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const disabled = role == "admin";
  const [_] = useTranslation("global");

  // const [governmentAreas, handleGovAreas] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [statusChangeErr, handleStatusChangeErr] = useState(false);

  // Form data
  const [amount, handleAmount] = useState(0);
  const [paymentType, handlePaymentType] = useState("");
  const [accountNumber, handleAccountNumber] = useState("");
  const [email, handleEmail] = useState("");
  const [bank_name, handleBank_name] = useState("");
  const [status, handleStatus] = useState("");
  const [marketer, SetMarketer] = useState("");
  const [bank_country, handleBank_country] = useState("");
  const [ecombo_account_name, handleEcombo_account_name] = useState("");
  const [bank_account_currency, handleBank_account_currency] = useState("");
  const [bank_account_holder_name, handleBank_account_holder_name] =
    useState("");
  const [IBAN, handleIBAN] = useState("");
  const [notes, handleNotes] = useState("");
  const [proof, setProof] = useState("");

  // Modal

  const get_payment_request = () => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/get-payment-request/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleLoader(false);
        handleAmount(res.data.amount);
        handlePaymentType(res.data.payment_method);
        handleEcombo_account_name(res.data.ecombo_account_name);
        handleEmail(res.data.email);
        handleStatus(res.data.status);
        SetMarketer(
          res.data.m_first_name
            ? res.data.m_first_name +
                " " +
                res.data.m_last_name +
                " " +
                "#" +
                res.data.m_id
            : ""
        );
        handleBank_name(res.data.bank_name);
        handleBank_country(res.data.bank_country);
        handleBank_account_currency(res.data.bank_account_currency);
        handleBank_account_holder_name(res.data.bank_account_holder_name);
        handleIBAN(res.data.IBAN);
        handleNotes(res.data.notes);
        handleAccountNumber(res.data.account_number);
        if (res.data.image) {
          // setFiles([...files,                     { source: res.data.image, options: { serverId: id } },])
        }
        setProof(res.data.image);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const create_invoice = () => {
    return new Promise((resolve, reject) => {
      handleLoader(true);  
  
      if (files.length) { 
        pond.current.processFiles().then(() => { 
          const success = true;  
          if (success) {
            resolve("Invoice created and files uploaded");
          } else {
            reject(new Error("Failed to upload files"));
          }
        }).catch((error) => { 
          handleLoader(false);  
          reject(new Error("File upload failed: " + error.message));
        });
      } else { 
        const success = true;  
        if (success) {
          resolve("Invoice created (no files to upload)");
        } else {
          reject(new Error("Failed to create invoice"));
        }
      }
    });
  };

  const update_payment_request = () => {
    handleLoader(true);
    AxiosController.put(
      `api/${slug}/${lang}/update-payment-request/${id}`,
      {
        payment_method: paymentType,
        bank_name: bank_name,
        email: email,
        bank_country: bank_country,
        bank_account_currency: bank_account_currency,
        bank_account_holder_name: bank_account_holder_name,
        IBAN: IBAN,
        account_number: accountNumber,
        notes: notes,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        get_payment_request();
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const [banks, setBanks] = useState([]);
  const [bank, setBank] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const action = async () => {
    try {
      await create_invoice(); 
      await change_order_status(
        slug,
        lang,
        token,
        id,
        status,
        handleStatusChangeErr,
        bank,
        handleLoader
      );
      console.log("Action completed successfully");
    } catch (error) {
      console.error("Error in action:", error); 
    }
  };

  useEffect(() => {
    get_payment_request();
    accounts(slug, lang, token, setBanks);
  }, [id]);
  const icon = <i className="pi pi-search"></i>;

  return (
    <Container style={{ position: "relative" }}>
      <Loader active={loading} />
      <ConfirmModal
        setShowModal={setShowModal}
        showModal={showModal}
        action={action}
        modalHeader={
          status === "Rejected" ? "rejectedHeader" : "approvedHeader"
        }
        modalBody={status === "Rejected" ? "rejectedBody" : "approvedBody"}
        confirmButton={
          status === "Rejected" ? "rejectedButton" : "approvedButton"
        }
      />
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div
          className="add-header d-flex justify-content-between align-items-center"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.payment_request")} #{id}
          </h2>
          <h2 className="mb-3 fs-5" style={{ color: "rgb(243, 168, 55)" }}>
            {marketer ?? ""}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <div
            className="order-right-container d-flex flex-column"
            style={{ flex: "7" }}
          >
            <div className="order-right-entry">
              <div className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4">
                <div
                  className="right-side d-flex flex-column"
                  style={{ flex: "3" }}
                >
                  {label_input(
                    _("panel.amount"),
                    parseInt(amount).toLocaleString(),
                    handleAmount,
                    true
                  )}
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    {_("panel.paymentMethod")}
                  </label>

                  <Form.Select
                    onChange={(e) => {
                      handlePaymentType(e.target.value);
                    }}
                    className="mb-3"
                    value={paymentType}
                    disabled={disabled || status !== "Pending"}
                  >
                    <option selected={true}></option>
                    {Object.keys(paymentRequestTypes).map((type) => {
                      if (type === "") {
                      } else {
                        return (
                          <option value={paymentRequestTypes[type]["arabic"]}>
                            {_(`panel.${type}`)}
                          </option>
                        );
                      }
                    })}
                  </Form.Select>
                  {paymentRequestTypes[paymentArabicEng[paymentType]][
                    "accountNumber"
                  ]
                    ? label_input(
                        _("panel.accountNumber"),
                        accountNumber,
                        handleAccountNumber,
                        disabled || status !== "Pending"
                      )
                    : ""}
                  {paymentRequestTypes[paymentArabicEng[paymentType]]["email"]
                    ? label_input(
                        _("panel.email"),
                        email,
                        handleEmail,
                        disabled || status !== "Pending"
                      )
                    : ""}
                  {paymentRequestTypes[paymentArabicEng[paymentType]][
                    "bank_name"
                  ]
                    ? label_input(
                        _("panel.bank_name"),
                        bank_name,
                        handleBank_name,
                        disabled || status !== "Pending"
                      )
                    : ""}
                  {paymentRequestTypes[paymentArabicEng[paymentType]][
                    "bank_country"
                  ]
                    ? label_input(
                        _("panel.bank_country"),
                        bank_country,
                        handleBank_country,
                        disabled || status !== "Pending"
                      )
                    : ""}
                  {paymentRequestTypes[paymentArabicEng[paymentType]][
                    "bank_account_currency"
                  ]
                    ? label_input(
                        _("panel.bank_account_currency"),
                        bank_account_currency,
                        handleBank_account_currency,
                        disabled || status !== "Pending"
                      )
                    : ""}
                  {paymentRequestTypes[paymentArabicEng[paymentType]][
                    "bank_account_holder_name"
                  ]
                    ? label_input(
                        _("panel.bank_account_holder_name"),
                        bank_account_holder_name,
                        handleBank_account_holder_name,
                        disabled || status !== "Pending"
                      )
                    : ""}
                  {paymentRequestTypes[paymentArabicEng[paymentType]]["IBAN"]
                    ? label_input(
                        "IBAN",
                        IBAN,
                        handleIBAN,
                        disabled || status !== "Pending"
                      )
                    : ""}
                  {label_textarea(
                    _("panel.notes"),
                    notes,
                    handleNotes,
                    disabled || status !== "Pending"
                  )}
                  {status !== "Rejected" && role === "admin" && (
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {_("panel.thebank")}
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setBank(e.target.value);
                        }}
                        disabled={
                          status === "Approved" || status === "Rejected"
                        }
                        value={bank}
                      >
                        <option selected={true} value={ecombo_account_name}>
                          {ecombo_account_name || _("panel.thebank")}
                        </option>
                        {banks.map((bank) => {
                          return <option value={bank.id}>{bank.name}</option>;
                        })}
                      </select>
                    </div>
                  )}
                  <div className="mb-3">
                    <Image
                      src={proof}
                      indicatorIcon={icon}
                      alt="Image"
                      preview
                      width="250"
                    />
                  </div>
                  <div className="mb-3">
                    {disabled && (
                      <>
                        {" "}
                        <FilePond
                          ref={pond}
                          files={files}
                          onprocessfiles={() => {
                            setFiles([]);
                          }}
                          onaddfile={() => {
                            // handleAddedImgs(1);
                          }}
                          onupdatefiles={setFiles}
                          allowMultiple={false}
                          maxFiles={1}
                          f
                          server={{
                            // load : {
                            //   url :proof

                            // },
                            remove: {},
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },

                            process: {
                              url:
                                BaseServerUrl +
                                `/api/${slug}/${lang}/add-invoice/${id}`,
                              onload: (e) => {
                                setProof(JSON.parse(e).image);
                                handleLoader(false);
                              },
                              onerror: (error) => {
                                console.error("Upload failed:", error);
                                handleLoader(false);
                              },
                            },
                          }}
                          instantUpload={false}
                          name="invoice"
                          labelIdle={`${_(
                            "panel.draglandpage"
                          )} <span class="filepond--label-action">${_(
                            "panel.browse"
                          )}</span>`}
                        />
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <TableSelectStatus
                      orderId={id}
                      currentStatus={status}
                      handleLoader={handleLoader}
                      disabled={disabled}
                      handleStatus={handleStatus}
                    />
                  </div>

                  <div className="mb-3">
                    <Button
                      style={{
                        backgroundColor: "rgba(89, 198, 201, 1)",
                        color: "white",
                        border: "none",
                        width: "100%",
                      }}
                      className="fs-5"
                      onClick={() => {
                        if (disabled) {
                          if (status === "Pending") {
                            update_payment_request();
                          } else {
                            if (!bank && status === "Approved") {
                              notifyError("قم باختيار البنك");
                            }
                            if (bank && status === "Approved") {
                              setShowModal(true);
                            }
                            if (status === "Rejected") {
                              setShowModal(true);
                            }
                          }
                          handleLoader(false);
                        }
                      }}
                    >
                      {_("panel.update")}
                    </Button>

                    {statusChangeErr ? <p>{_("panel.statusChangeErr")}</p> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
const label_input = (
  label,
  newWarehouseName,
  handleNewWarehouseName,
  disabled
) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
};
const label_textarea = (label, Data, handleData, disabled) => {
  return (
    <div className="mb-3 d-flex flex-column">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <textarea
        class="form-control"
        rows={3}
        onInput={(e) => {
          handleData(Data);
        }}
        disabled={disabled}
        value={Data}
      ></textarea>
    </div>
  );
};
export default Transaction;
