import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Button,  Modal , Pagination } from "react-bootstrap";
import SuppliersTableRow from "./SuppliersTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
const get_warehouses = (
  slug,
  lang,
  token,
  handleWarehouses,
  courierId,
  page,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/fulfillments/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleWarehouses(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const Fulfillment = () => {
  const { slug, lang, courierId } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [suppliers, handleSuppliers] = useState({});
  const [loading, handleLoader] = useState(false);
  const [user, setUser] = useState("");
  const [fulfillment_profit, setFulfillmentProfit] = useState(0);
  const [confirmation_price, setConfirmationPrice] = useState(0);
  const [cancellation, setCancellation] = useState(0);
  const [userErr, setUserErr] = useState("");
  const [fulfillment_profitErr, setFulfillmentProfitErr] = useState("");
  const [confirmation_priceErr, setConfirmationPriceErr] = useState("");
  const [cancellationErr, setCancellationErr] = useState("");
  const [show, setShow] = useState(false);
  const [page, handlePage] = useState(urlParams.get("page") || 1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const createWarehouse = () => {
    handleLoader(true);
    AxiosController.post(
      `api/${slug}/${lang}/fulfillments/new`,
      {
        user_id: user,
        fulfillment_price: fulfillment_profit,
        confirmation_price: confirmation_price,
        cancellation_price: cancellation,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        const nextPage = parseInt(urlParams.get("page"), 10) || 1;

        get_warehouses(
          slug,
          lang,
          token,
          handleSuppliers,
          courierId,
          nextPage,
          handleLoader
        );
        setShow(false);
        setFulfillmentProfitErr('');
        setConfirmationPriceErr('');
        setCancellationErr('');
        setUserErr('');
        setUser("");
        setFulfillmentProfit(0);
        setCancellation(0);
      })
      .catch((e) => {
        handleLoader(false);
        setFulfillmentProfitErr(
          e.response.data.errors.fulfillment_price
            ? e.response.data.errors.fulfillment_price[0]
            : ""
        );
        setConfirmationPriceErr(
          e.response.data.errors.confirmation_price
            ? e.response.data.errors.confirmation_price[0]
            : ""
        );
        setCancellationErr(
          e.response.data.errors.cancellation_price
            ? e.response.data.errors.cancellation_price[0]
            : ""
        );
        setUserErr(
          e.response.data.errors.user_id
            ? e.response.data.errors.user_id[0]
            : ""
        );
      });
  };
  useEffect(() => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    handlePage(nextPage);
    get_warehouses(
      slug,
      lang,
      token,
      handleSuppliers,
      courierId,
      nextPage,
      handleLoader
    );
  }, [slug, lang, location.search ,token , courierId  ]);

  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    >
      <Button
        variant="primary"
        onClick={() => {
          handleShow(true);
        }}
        style={{ background: "#59C6C9", color: "white", border: "none" }}
      >
        {_("panel.add")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">{_("panel.add")}</Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          {label_input(_("panel.code2"), user, setUser)}
          {userErr ? <Alert variant={"danger"}>{userErr}</Alert> : ""}
          {label_input(
            _("panel.fulfillmentprofit"),
            fulfillment_profit,
            setFulfillmentProfit
          )} {fulfillment_profitErr ? (
            <Alert variant={"danger"}>{fulfillment_profitErr}</Alert>
          ) : (
            ""
          )}
           {label_input(
            _("panel.confirmation_price"),
            confirmation_price,
            setConfirmationPrice
          )}
          {confirmation_priceErr ? (
            <Alert variant={"danger"}>{confirmation_priceErr}</Alert>
          ) : (
            ""
          )}

          {label_input(
            _("panel.cancellationprofit"),
            cancellation,
            setCancellation
          )}
          {cancellationErr ? (
            <Alert variant={"danger"}>{cancellationErr}</Alert>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button
            variant="primary"
            onClick={() => {
              createWarehouse();
            }}
          >
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.fulfillmentpricing")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {suppliers ? (
            <SuppliersTableRow get_warehouses={()=>  
              get_warehouses(
              slug,
              lang,
              token,
              handleSuppliers,
              courierId,
              1,
              handleLoader
            )} payments_list={suppliers.data ?? []} />
          ) : (
            ""
          )}
        </div>
        {suppliers ? (
          <Pagination className="m-auto flex-wrap" dir="ltr">
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => (page - 1 > 0 ? changeParam("page", page - 1) : 1)}
            />
            {suppliers["links"]
              ? suppliers["links"].map((element, index, arr) => {
                  if (index === 0 || arr.length - 1 === index) {
                    return "";
                  } else {
                    return (
                      <Pagination.Item
                        active={element["active"] ? true : false}
                        onClick={(e) =>
                          element["label"] !== "..."
                            ? changeParam("page", parseInt(element["label"]))
                            : ""
                        }
                      >
                        {element["label"]}
                      </Pagination.Item>
                    );
                  }
                })
              : ""}
            <Pagination.Next onClick={() => changeParam("page", page + 1)} />
            <Pagination.Last
              onClick={() => changeParam("page", suppliers["last_page"])}
            />
          </Pagination>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
export default Fulfillment;
