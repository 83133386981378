import React, { useEffect, useRef, useState } from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { currancyConvertor } from "../../../../utils/currancyConvertor";
import { Form, Table } from "react-bootstrap";
import PieChartTemplate from "../../../charts/ChartTemplate";
import { Pie } from "react-chartjs-2";
import { MultiSelect } from "primereact/multiselect";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { format, set } from "date-fns";
import { Tabs } from "@mantine/core";

const Couriers = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [canceledTotal, seCanceledTotal] = useState([0, 0]);
  const [deliveredTotal, setDeliveredTotal] = useState([0, 0, 0,0]);
  const [shippedTotal, setShippedTotal] = useState([0, 0, 0]);
  const [returnedTotal, setReturnedTotal] = useState([0, 0]);
  const [quantity, setQuantity] = useState(0);
  const [statsArray, SetStatsArray] = useState([0, 0, 0, 0]);
  const [couriers, setCouriers] = useState([]);
  const [grouped_stats_status, setGrouped_stats_status] = useState([]);
  const [grouped_stats_areas, setGrouped_stats_areas] = useState([]);
  const [grouped_stats_products, setGrouped_stats_products] = useState([]);
  const [productsTotalOrders, setProductsTotalOrders] = useState(1);
  const [productsTotalOrders2, setProductsTotalOrders2] = useState(0);
  const [productsTotalOrders3, setProductsTotalOrders3] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentCourier, setCurrentCourier] = useState();

  const [selectedGovs, setSelectedGovs] = useState([]);
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortData = (key, data, setData) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (typeof a[key] === "number") {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      } else {
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      }
    });

    setData(sortedData);
  };

  const govs = [];

  couriers.forEach((gov) => {
    govs.push({ name: gov.name, Code: gov.id });
  });
  const status_grouped_stats = (courier_id, startDate, endDate) => {
    AxiosController.get(`api/${slug}/${lang}/get-courier-status-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let total = res.data.reduce(
          (count, i) => count + Number(i["orders_count"]),
          0
        );
        let total_quantity = res.data.reduce(
          (count, i) => count + Number(i["total_quantity"]),
          0
        );

        setQuantity(total_quantity || 1);
        setTotalOrders(total || 1);
        setGrouped_stats_status(res.data);
        res.data.map((status_stat) => {
          if (
            status_stat["status"] === "Cash Delivery" ||
            status_stat["status"] === "Delivered"
          )
            SetStatsArray((arr) => {
              let tempArr = [...arr];
              tempArr[0] += (status_stat["orders_count"] / total) * 100;
              setDeliveredTotal((prev) => [
                prev[0] + Number(status_stat["orders_count"]),
                prev[1] + Number(status_stat["total_quantity"]),
                prev[2] + Number(status_stat["value"]),
                prev[3] 
              ]);
              return tempArr;
            });

            if (status_stat["status"] === "Delivered") {
              setDeliveredTotal((prev) => [
                prev[0], 
                prev[1], 
                prev[2], 
                prev[3] + Number(status_stat["value"])  
              ]);
            }
          if (
            status_stat["status"] === "Canceled" ||
            status_stat["status"] === "Pending Cancel"
          ) {
            SetStatsArray((arr) => {
              let tempArr = [...arr];
              tempArr[1] += (status_stat["orders_count"] / total) * 100;
              return tempArr;
            });

            seCanceledTotal((prev) => [
              prev[0] + Number(status_stat["orders_count"]),
              prev[1] + Number(status_stat["total_quantity"]),
            ]);
          }

          if (status_stat["status"] === "Pending Return")
            SetStatsArray((arr) => {
              let tempArr = arr;
              tempArr[2] += (status_stat["orders_count"] / total) * 100;
              setReturnedTotal((prev) => [
                prev[0] + Number(status_stat["orders_count"]),
                prev[1] + Number(status_stat["total_quantity"]),
              ]);
              return arr;
            });

          if (status_stat["status"] === "Shipped")
            SetStatsArray((arr) => {
              let tempArr = arr;
              tempArr[3] += (status_stat["orders_count"] / total) * 100;
              setShippedTotal((prev) => [
                prev[0] + Number(status_stat["orders_count"]),
                prev[1] + Number(status_stat["total_quantity"]),
                prev[2] + Number(status_stat["value"]),
              ]);
              return arr;
            });
        });
      })
      .catch((e) => {});
  };
  const area_grouped_stats = (courier_id, startDate, endDate) => {
    AxiosController.get(`api/${slug}/${lang}/get-courier-area-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let totalAreaOrders = res.data.reduce((prv, prd_order) => {
          return prd_order["stats"] + prv;
        }, 0);
        setProductsTotalOrders3(totalAreaOrders);

        setGrouped_stats_areas(res.data);
      })
      .catch((e) => {});
  };
  const products_grouped_stats = (courier_id, startDate, endDate) => {
    AxiosController.get(`api/${slug}/${lang}/get-courier-product-stats/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        couriers: courier_id.map((courier) => courier.Code),
      },
    })
      .then((res) => {
        let totalProductsOrders = res.data.reduce((prv, prd_order) => {
          return Number(prd_order["totalQ"]) + prv;
        }, 0);
        let totalProductsOrders2 = res.data.reduce((prv, prd_order) => {
          return Number(prd_order["deliveredQ"]) + prv;
        }, 0);
        setProductsTotalOrders(totalProductsOrders);
        setProductsTotalOrders2(totalProductsOrders2);
        setGrouped_stats_products(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const get_couriers = () => {
    AxiosController.get(`api/${slug}/${lang}/couriers/get-all-couriers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCouriers(res.data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    handleLoader(true);
    get_couriers();
    handleLoader(false);
  }, [slug, lang, token, handleLoader]);
  const totalShippedCount = grouped_stats_products.reduce(
    (acc, item) => acc + item.shipped_count,
    0
  );
  const totalOrdersNum = grouped_stats_products.reduce(
    (acc, item) => acc + item.total_product_orders,
    0
  );
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleLoader(true);
    status_grouped_stats(selectedGovs, apiStartDate, apiEndDate);
    area_grouped_stats(selectedGovs, apiStartDate, apiEndDate);
    products_grouped_stats(selectedGovs, apiStartDate, apiEndDate);
    SetStatsArray([0, 0, 0, 0]);
    handleLoader(false);
  };
  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.couriers")}</title>
      </Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.couriers")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
            <DateRangePickerComponent
              state={state}
              setState={setState}
              applyDateRange={() => applyDateRange()}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center w-100 gap-2 h-100">
          <MultiSelect
            value={selectedGovs}
            onChange={(e) => {
              setSelectedGovs(e.value);
            }}
            options={govs}
            optionLabel="name"
            placeholder={_("panel.couriers")}
            maxSelectedLabels={3}
            className="w-100 align-items-center px-3 py-1"
          />
          <button
            className="btn px-3"
            style={{
              background: "rgba(89, 198, 201, 1)",
              color: "white",
            }}
            onClick={(e) => {
              handleLoader(true);
              setProductsTotalOrders(1);
              setProductsTotalOrders2(1);
              setProductsTotalOrders3(1);
              status_grouped_stats(selectedGovs, startDate, endDate);
              area_grouped_stats(selectedGovs, startDate, endDate);
              products_grouped_stats(selectedGovs, startDate, endDate);
              setCurrentCourier(selectedGovs);
              SetStatsArray([0, 0, 0, 0]);

              // handleLoader(false);
            }}
          >
            {_("panel.show")}
          </button>
        </div>
      </div>
      <Tabs defaultValue="table1" className="w-100">
        <Tabs.List>
          <Tabs.Tab value="table1" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.table1")}
          </Tabs.Tab>
          <Tabs.Tab value="table2" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.table2")}
          </Tabs.Tab>

          <Tabs.Tab value="table3" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.table3")}
          </Tabs.Tab>
          <Tabs.Tab value="table4" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.table4")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="table1">
          <div
            className="boxes-container d-flex flex-wrap gap-2 mt-4"
            // style={{ direction: "rtl" }}
            dir={box_head_dir}
          >
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />
                <p className="flex-grow-1">{_("panel.orders")} </p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(totalOrders).toLocaleString()}{" "}
                  {_("panel.orders_count")}{" "}
                </p>
                <p>
                  {" "}
                  {Number(quantity).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
              </div>
            </div>
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"dollarSign"} />
                <p className="flex-grow-1">{_("panel.نسبة التسليمات")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(deliveredTotal[0]).toLocaleString()}{" "}
                  {_("panel.orders_count")}
                </p>
                <p>
                  {Number(deliveredTotal[1]).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
                <p>
                  {statsArray[0].toLocaleString()} {"%"} <br />
                </p>
              </div>
            </div>
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />
                <p className="flex-grow-1">{_("panel.نسبة الإلغاءات")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(canceledTotal[0]).toLocaleString()}{" "}
                  {_("panel.orders_count")}
                </p>
                <p>
                  {Number(canceledTotal[1]).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
                <p>
                  {statsArray[1].toLocaleString()}
                  {"%"}
                </p>
              </div>
            </div>
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"dollarSign"} />
                <p className="flex-grow-1">{_("panel.نسبة المرتجعات")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(returnedTotal[0]).toLocaleString()}{" "}
                  {_("panel.orders_count")}
                </p>
                <p>
                  {Number(returnedTotal[1]).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
                <p>
                  {statsArray[2].toLocaleString()}

                  {"%"}
                </p>
              </div>
            </div>

            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />

                <p className="flex-grow-1">{_("panel.في الشحن")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(shippedTotal[0]).toLocaleString()}{" "}
                  {_("panel.orders_count")}
                </p>
                <p>
                  {Number(shippedTotal[1]).toLocaleString()}{" "}
                  {_("panel.orders_quantity")}
                </p>
                <p>
                  {statsArray[3].toLocaleString()}

                  {"%"}
                </p>
              </div>
            </div>

            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"dollarSign"} />
                <p className="flex-grow-1">{_("panel.expected_collection")}</p>
              </div>
              <div className="middle fs-5">
                <p>{Number(shippedTotal[2]).toLocaleString()}</p>
              </div>
            </div>

            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />

                <p className="flex-grow-1">{_("panel.collected_amount")}</p>
              </div>
              <div className="middle fs-5">
                <p>{Number(deliveredTotal[2]).toLocaleString()}</p>
              </div>
            </div>
            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"dollarSign"} />

                <p className="flex-grow-1"> {_("panel.total_amount")}</p>
              </div>
              <div className="middle fs-5">
                <p>
                  {Number(deliveredTotal[2] + shippedTotal[2]).toLocaleString()}
                </p>
              </div>
            </div>

            <div className="report-box col-12 col-lg-2 col-md-5 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
              <div className="top d-flex align-items-center gap-2">
                <IconsGen icon_name={"orangdash"} />

                <p className="flex-grow-1">{_("panel.deliveredCollected")}</p>
              </div>
              <div className="middle fs-5">
                <p>{Number(deliveredTotal[3]).toLocaleString()}</p>
              </div>
            </div>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="table2">
          <Table
            responsive
            dir={box_head_dir}
            className="reports-table text-nowrap text-center mt-4"
          >
            <thead>
              <tr>
                <th>{_("panel.status")}</th>
                <th>
                  {_("panel.totalorders2")} ({totalOrders.toLocaleString()})
                </th>
                <th>{_("panel.value")}</th>
                <th>{_("panel.shippingCostsValue")}</th>
              </tr>
            </thead>
            <tbody>
              {grouped_stats_status.map((stat) => {
                return (
                  <tr>
                    <td>{_(`panel.${stat["status"]}`)}</td>
                    <td>
                      {stat["orders_count"]} (
                      {(
                        (stat["orders_count"] / totalOrders) *
                        100
                      ).toLocaleString()}
                      %)
                    </td>
                    <td>{stat["value"].toLocaleString()}</td>
                    <td>{stat["shipping_cost"].toLocaleString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tabs.Panel>

        <Tabs.Panel value="table3">
          <Table
            responsive
            dir={box_head_dir}
            className="reports-table text-nowrap text-center"
          >
            <thead>
              <tr>
                <th
                  onClick={() =>
                    sortData(
                      "arabic_name",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.gov")}
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "shipped_order_price",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.shipped_orders")} (المبلغ)
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "delivered_orders",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.deliveredOrders")}
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "shipped_orders",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.shippedOrders")}
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "canceled_orders",
                      grouped_stats_areas,
                      setGrouped_stats_areas
                    )
                  }
                >
                  {_("panel.canceledOrders")}
                </th>
              </tr>
            </thead>
            <tbody>
              {grouped_stats_areas.map((stat) => (
                <tr key={stat["arabic_name"]}>
                  <td>{stat["arabic_name"]}</td>
                  <td>
                    {Number(stat["shipped_order_price"]).toLocaleString()}{" "}
                    <br />({Number(stat["shipped_orders"]).toLocaleString()}){" "}
                    {_("panel.orders_count")}
                  </td>
                  <td>
                    {Number(stat["delivered_order_price"]).toLocaleString()}{" "}
                    <br />({Number(stat["delivered_orders"]).toLocaleString()}){" "}
                    {_("panel.orders_count")} <br />(
                    {(
                      (Number(stat["delivered_orders"]) /
                        Number(stat["total_orders"])) *
                      100
                    ).toLocaleString()}{" "}
                    %)
                  </td>
                  <td>{stat["shipped_orders"]}</td>
                  <td>
                    {Number(stat["canceled_order_price"]).toLocaleString()}
                    <br />({Number(
                      stat["canceled_orders"]
                    ).toLocaleString()}) {_("panel.orders_count")} <br />(
                    {(
                      (Number(stat["canceled_orders"]) /
                        Number(stat["total_orders"])) *
                      100
                    ).toLocaleString()}{" "}
                    %)
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tabs.Panel>

        <Tabs.Panel value="table4">
          <Table
            responsive
            dir={box_head_dir}
            className="reports-table text-nowrap text-center mt-4"
          >
            <thead>
              <tr>
                <th
                  onClick={() =>
                    sortData(
                      "product.id",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.title")}
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "totalOrders",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.totalorders2")} ({totalOrders.toLocaleString()})
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "totalQ",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.totalQ")} ({productsTotalOrders.toLocaleString()})
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "deliveredOrders",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.deliveredOrders")}(
                  {Math.round(
                    (statsArray[0] / 100) * totalOrders
                  ).toLocaleString()}
                  ) (
                  {Number(
                    (Math.round((statsArray[0] / 100) * totalOrders) /
                      totalOrders) *
                      100
                  ).toLocaleString()}{" "}
                  %)
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "deliveredQ",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.totalDeliveredQ")} (
                  {productsTotalOrders2.toLocaleString()})
                </th>
                <th
                  onClick={() =>
                    sortData(
                      "totalShippedOrders",
                      grouped_stats_products,
                      setGrouped_stats_products
                    )
                  }
                >
                  {_("panel.shippedOrders")}
                </th>
              </tr>
            </thead>
            <tbody>
              {grouped_stats_products.map((stat) => (
                <tr key={stat["product"]["id"]}>
                  <td>
                    <span className="fw-bold"> #{stat["product"]["id"]}</span>{" "}
                    <br /> {stat["product"]["title"]}
                  </td>
                  <td>{stat["totalOrders"].toLocaleString()}</td>
                  <td>{stat["totalQ"].toLocaleString()}</td>
                  <td>
                    {stat["deliveredOrders"]} <br /> (
                    {Number(
                      (stat["deliveredOrders"] / stat["totalOrders"]) * 100
                    ).toLocaleString()}
                    %)
                  </td>
                  <td>{stat["deliveredQ"].toLocaleString()}</td>
                  <td>{stat["totalShippedOrders"].toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
const dataConstructor = (
  dataArray,
  labelName,
  totalAreaOrders,
  valueName = "stats"
) => {
  return {
    labels: dataArray.map((data) => data[labelName]),
    datasets: [
      {
        data: dataArray.map((data) => data["stats"].toLocaleString()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
      },
    ],
  };
};
export default Couriers;
