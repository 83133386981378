import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import StatisticsSearchComponent from "./search components/StatisticsSearchComponent";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import * as XLSX from "xlsx"; // Import XLSX library
import IconsGen from "../../../icons_generator/IconsGen";

const StagnantProducts = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [allData, handleAllData] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [search, handleSearch] = useState(false);
  const [days, setDays] = useState("");
  const [productId, setProductId] = useState("");

  useEffect(() => {
    setDays(urlParams.get("days") || "");
    setProductId(urlParams.get("product_id") || "");
  }, [urlParams]);

  const get_stagnant_products = () => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/stagnant-products`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        days: days,
        product_id: productId,
      },
    })
      .then((res) => {
        handleLoader(false);
        handleAllData(res?.data);
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  useEffect(() => {
    get_stagnant_products();
  }, [days, productId]);

  // Function to convert data to Excel and trigger download
  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(allData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Stagnant Products");
    XLSX.writeFile(wb, "stagnant_products.xlsx");
  };

  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.stagnantProducts")}</title>
      </Helmet>{" "}
      {(days || productId) && allData.length > 0 && (
        <button
          className="btn"
          style={{ border: "1px solid #172B4D", color: "#172B4D" }}
          onClick={downloadExcel}
        >
          <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
        </button>
      )}
      <div className="pt-2 ">
        <Button
          className="fs-5 px-5 w-25 mb-4"
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
          }}
          onClick={() => {
            handleSearch(!search);
          }}
        >
          {_("panel.search")}
        </Button>
      </div>
      {search ? <StatisticsSearchComponent type={"stagnantProducts"} /> : ""}
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.stagnantProducts")}{" "}
            {days ? `  خلال اخر ${days} يوم` : "خلال اخر 30 يوم"}
          </h3>
        </div>

        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table>
              <thead>
                <tr className="text-center">
                  <th>{_("panel.products")}</th>
                  <th>{_("panel.seller")}</th>
                  <th>{_("panel.total_delivered")}</th>
                  <th>{_("panel.totalorders")}</th>
                  <th>{_("panel.Soldquantity")}</th>
                  <th>{_("panel.left")}</th>
                  <th>{_("panel.totalAmount")}</th>
                  <th>{_("panel.productValue")}  </th>
                </tr>
              </thead>
              <tbody>
                {allData?.length > 0 &&
                  allData.map((item) => (
                    <tr className="text-center" key={item?.id}>
                      <td>
                        <span className="fw-bold">{item?.id} #</span> <br />
                        {item?.title}
                      </td>
                      <td>
                        {item?.seller_id} #<br /> {item?.seller_first_name}{" "}
                        {item?.seller_last_name}
                      </td>
                      <td>{Number(item?.delivered_orders).toLocaleString()}</td>
                      <td>{Number(item?.total_orders).toLocaleString()}</td>
                      <td>{Number(item?.total_quantity).toLocaleString()}</td>
                      <td>{Number(item?.left_stock).toLocaleString()}</td>
                      <td>{Number(item?.total_stock).toLocaleString()}</td>
                      <td>{(Number(item?.left_stock)* Number(item?.average_variant_price)).toLocaleString()}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StagnantProducts;
