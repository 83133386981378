import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../loader/Loader";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";

const MostFrequentBuyers = () => {
  const { slug, lang } = useParams();
  const { t } = useTranslation("global");
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openDropdown, setOpenDropdown] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: "total_orders", direction: "desc" });
  // const location = useLocation();
  // const urlParams = new URLSearchParams(location.search);
  // const [search, handleSearch] = useState(false);
  // const [marketerId, setMarketerId] = useState("");
  // const [courierId, setCourierId] = useState("");
  // const [productId, setProductId] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  // useEffect(() => {
  //   setMarketerId(urlParams.get("marketer_id") || "");
  //   setCourierId(urlParams.get("courier_id") || "");
  //   setProductId(urlParams.get("product_id") || "");
  //   setStartDate(urlParams.get("start_date") || "");
  //   setEndDate(urlParams.get("end_date") || "");
  // }, [urlParams]);

  const getMostFrequentBuyers = (startDate, endDate) => {
    setLoading(true);
    AxiosController.get(`api/${slug}/${lang}/reports/most-frequent-buyers`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { start_date: startDate, end_date: endDate 

          // marketer_id: marketerId,
        // courier_id: courierId,
        // product_id: productId,
               },
    })
      .then((res) => {
        let sortedData = res?.data.clients.sort((a, b) => (b.total_orders || 0) - (a.total_orders || 0)); // Default sorting
        setData(sortedData);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getMostFrequentBuyers();
  }, []);

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });

  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    getMostFrequentBuyers(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
  };

  const toggleDropdown = (index) => {
    setOpenDropdown((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleSort = (key) => {
    let direction = "asc";

    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      const aValue = a[key] ?? "";
      const bValue = b[key] ?? "";

      if (typeof aValue === "string" && typeof bValue === "string") {
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      return 0;
    });

    setData(sortedData);
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "  🔼" : "  🔽";
    }
    return "";
  };

  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>         {sortConfig.direction=== 'asc' ? t('panel.most_canceled_buyers'): t("panel.mostFrequentBuyers")}   
        </title>
      </Helmet>

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />
        <div className="overflow-auto reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
         {sortConfig.direction=== 'asc' ? t('panel.most_canceled_buyers'): t("panel.mostFrequentBuyers")}   
          </h3>
          <DateRangePickerComponent
            state={state}
            setState={setState}
            applyDateRange={applyDateRange}
          />
        </div>

        <div className="row" dir={lang === "en" ? "ltr" : "rtl"}>
          <div className="col-12 gy-4 mt-4" style={{ overflowY: "auto" }}>
            <div className="container">
              <Table responsive dir={lang === "en" ? "ltr" : "rtl"}>
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th onClick={() => handleSort("customer_name")} style={{ cursor: "pointer" }}>
                      {t("panel.customer_name")} {getSortIndicator("customer_name")}
                    </th>
                    <th onClick={() => handleSort("total_orders")} style={{ cursor: "pointer" }}>
                      {t("panel.total_orders")} {getSortIndicator("total_orders")}
                    </th>
                    <th onClick={() => handleSort("total_delivered")} style={{ cursor: "pointer" }}>
                      {t("panel.total_delivered")} {getSortIndicator("total_delivered")}
                    </th>
                    <th onClick={() => handleSort("total_canceled")} style={{ cursor: "pointer" }}>
                      {t("panel.canceledOrders")} {getSortIndicator("total_canceled")}
                    </th>
                    <th>{t("panel.product")}</th>
                    <th>{t("panel.gov")}</th>
                    <th>{t("panel.marketers")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data.map((item, index) => (
                      <tr key={index} className="text-center">
                        <th scope="row">{index + 1}</th>
                        <td>
                          {item?.customer_name} <br />
                          {item?.phone_1}
                        </td>
                        <td>{item?.total_orders}</td>
                        <td>{item?.total_delivered}</td>
                        <td>{item?.total_canceled}</td>
                        <td>
                          <button
                            className="btn btn-link"
                            onClick={() => toggleDropdown(`products-${index}`)}
                          >
                            {t("panel.show_products")}
                          </button>
                          {openDropdown[`products-${index}`] && (
                            <ul className="list-unstyled">
                              {item.products.map((product, mIndex) => (
                                <li key={mIndex}>
                                  <span className="fw-bold">{product.product_id} #</span>
                                  <br />
                                  {product.title}
                                </li>
                              ))}
                            </ul>
                          )}
                        </td>
                        <td>
                          {item.governments && item.governments.length > 0
                            ? item.governments.map((gov, index) => (
                                <span key={index}>
                                  {gov.gov_name}
                                  {index !== item.governments.length - 1 && ", "}
                                </span>
                              ))
                            : "-"}
                        </td>
                        <td>
                          <button
                            className="btn btn-link"
                            onClick={() => toggleDropdown(`marketers-${index}`)}
                          >
                            {t("panel.show_marketers")}
                          </button>
                          {openDropdown[`marketers-${index}`] && (
                            <ul className="list-unstyled">
                              {item.marketers.map((marketer, mIndex) => (
                                <li key={mIndex}>
                                  <span className="fw-bold">{marketer.marketer_id} #</span>
                                  <br />
                                  {marketer.first_name} {marketer.last_name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostFrequentBuyers;
