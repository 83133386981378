import React, { useEffect } from "react";
import { 
  useParams,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Mainbar from "./components/navbar/Mainbar";
import i18next from "i18next";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import Product from "./pages/home/Product";
import Cart from "./pages/home/Cart";
import PlaceOrder from "./pages/home/PlaceOrder";
import ProtectedRoute from "./components/authentication/ProtectedRoute";
import Panel from "./pages/panel/Panel";
import SuccessOrder from "./pages/home/SuccessOrder";
import NavigateToRoot from "./components/authentication/NavigateToRoot";
import Category from "./pages/home/Category";
import Contact from "./pages/home/Contact";
import OrderNow from "./pages/home/OrderNow";
import NewArrivalPage from "./pages/home/NewArrivalPage";
import MostSellingPage from "./pages/home/MostSellingPage";
import OfferItem from "./pages/home/Offer";
import PaymentAndDeliveryOptions from "./components/payment-and-delivery-options/PaymentAndDeliveryOptions";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
import ReturnAndExchangePolicy from "./components/return-and-exchange-policy/ReturnAndExchangePolicy";
import { StaticHome } from "./pages/static home/StaticHome";
import Header from "./components/static home/Header";
import FallingImages from "./components/custom-falling-image/FallingImages";
import ramadan from "./imgs/ramadan.png";
const MainIndex = () => {
  let { lang, slug } = useParams();
  const isAuthenticated = Boolean(localStorage.getItem("token"));
  const role = localStorage.getItem("role") || "";
  const location = useLocation();  
  const isHomePage =
    location.pathname === "/" ||
    location.pathname === `/${slug}/${lang}/` ||
    location.pathname === `/${slug}/${lang}`;
  useEffect(() => {
    if (lang !== "en" && lang !== "ar") {
      lang = "ar";
    }
    i18next.changeLanguage(lang);
  }, [lang]);

  return (
    <>
      {isHomePage && <FallingImages imageSrc={ramadan} />}
      {isAuthenticated ? (
        <>
          <div className="d-flex flex-column min-vh-100">
            {" "}
            <main className="flex-grow-1">
              {" "}
              <Mainbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="/category/:id" element={<Category />} />
                <Route
                  path="/payment-and-delivery-options"
                  element={<PaymentAndDeliveryOptions />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/return-and-exchange-policy"
                  element={<ReturnAndExchangePolicy />}
                />
                <Route path="/new-arrival/:id" element={<NewArrivalPage />} />
                <Route path="/most-selling/:id" element={<MostSellingPage />} />
                <Route path="/category/:id" element={<Category />} />
                <Route path="/panel/*" element={<Panel />} />

                <Route
                  path="/cart/"
                  element={
                    <ProtectedRoute
                      component={Cart}
                      role={role}
                      roles={["AFFILIATE", "ADMIN", "EMPLOYEE"]}
                    />
                  }
                />

                <Route path="/contact-us" element={<Contact />} />
                <Route
                  path="/order-now/:productId/:variant"
                  element={<OrderNow />}
                />
                <Route path="/offer/:id" element={<OfferItem />} />

                <Route
                  path="/place-order/"
                  element={
                    <ProtectedRoute
                      component={PlaceOrder}
                      role={role}
                      roles={["AFFILIATE", "ADMIN", "EMPLOYEE", "FULFILLMENT"]}
                    />
                  }
                />

                <Route
                  path="/success-order/"
                  element={
                    <ProtectedRoute
                      component={SuccessOrder}
                      role={role}
                      roles={["AFFILIATE", "ADMIN", "EMPLOYEE", "FULFILLMENT"]}
                    />
                  }
                />

                <Route path="*" element={<NavigateToRoot />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<StaticHome />} />

            <Route
              path="/payment-and-delivery-options"
              element={
                <div className="d-flex flex-column min-vh-100">
                  <Header />
                  <main className="flex-grow-1">
                    <PaymentAndDeliveryOptions />
                  </main>
                  <Footer />
                </div>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <div className="d-flex flex-column min-vh-100">
                  <Header />
                  <main className="flex-grow-1">
                    <PrivacyPolicy />
                  </main>
                  <Footer />
                </div>
              }
            />
            <Route
              path="/return-and-exchange-policy"
              element={
                <div className="d-flex flex-column min-vh-100">
                  <Header />
                  <main className="flex-grow-1">
                    <ReturnAndExchangePolicy />
                  </main>
                  <Footer />
                </div>
              }
            />
          </Routes>
        </>
      )}
    </>
  );
};

export default MainIndex;
