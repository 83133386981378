import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainIndex from "./MainIndex";
import Login from "./pages/registerations/Registeration";
import EmailVerify from "./pages/registerations/EmailVerify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global_styles.css";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { ConfigProvider } from "antd";
import NavigateToRoot from "./components/authentication/NavigateToRoot";
import { PrimeReactProvider } from "primereact/api";
import AutomaticLogout from "./components/authentication/AutomaticLogout";
import { Helmet } from "react-helmet";

import { UserProvider } from "./components/contexts/UserContext";
const App = () => {
  return (
    <UserProvider>
      <MantineProvider>
        <Helmet>
          <title>ايكومبو شريك نجاحك في التجارة الالكترونية - ecombo</title>
        </Helmet>
        <ToastContainer />

        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: "rgba(89, 198, 201, 1)",
              borderRadius: 2,

              // Alias Token
              colorBgContainer: "white",
            },
          }}
        >
          <PrimeReactProvider>
            <Router>
              <AutomaticLogout>
                <Routes>
                  <Route path="/:slug/:lang/*" element={<MainIndex />} />
                  <Route path="/:lang/auth/*" element={<Login />} />
                  <Route path="/:lang/email/verify" element={<EmailVerify />} />
                  <Route path="*" element={<NavigateToRoot />} />
                </Routes>
              </AutomaticLogout>
            </Router>
          </PrimeReactProvider>
        </ConfigProvider>
      </MantineProvider>
    </UserProvider>
  );
};

export default App;
