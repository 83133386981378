import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../components/authentication/AxiosController";
import PlaceCartItem from "../../components/cart/PlaceCartItem";
import Loader from "../../components/loader/Loader";
import { UserContext } from "../../components/contexts/UserContext";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const PlaceOrder = () => {
  const { slug, lang } = useParams();
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  if (token) {
  } else {
    Navigate(`/ar/auth/login`);
  }
  const [_] = useTranslation("global");
  const [cart, handleCart] = useState([]);
  const [governments, handleGovs] = useState([]);
  // const [governmentAreas, handleGovAreas] = useState([]);
  const [loading, handleLoader] = useState(false);

  const [shippingcost, handleShippingCost] = useState(0);

  // order data
  const [orderClientName, handleOrderClientName] = useState("");
  const [altName, setAltname] = useState("");
  const [orderClientNameErr, handleOrderClientNameErr] = useState("");
  const [orderClientPhone1, handleOrderClientPhone1] = useState("");
  const [orderClientPhone1Err, handleOrderClientPhone1Err] = useState("");
  const [orderClientPhone2, handleOrderClientPhone2] = useState("");
  const [orderClientGov, handleOrderClientGov] = useState(0);
  const [orderClientGovErr, handleOrderClientGovErr] = useState("");
  const [orderClientAddress, handleOrderClientAddress] = useState("");
  const [orderClientAddressErr, handleOrderClientAddressErr] = useState("");
  const [orderClientPublicNotes, handleOrderClientPublicNotes] = useState("");
  const [orderClientPrivateNotes, handleOrderClientPrivateNotes] = useState("");
  const [zeroCOD, setZeroCOD] = useState(false);
  const [orderLink, setOrderLink] = useState("");
  const [orderLinkErr, setOrderLinkErr] = useState("");
  const [COD, setCOD] = useState(0);
  const [ref_orderErr, setRef_orderErr] = useState("");
  const [ref_order, setRef_order] = useState("");
  const { userData } = useContext(UserContext);

  const totalPrice = cart.reduce((prev, item) => {
    return prev + item.price * item.quantity;
  }, 0);
  const totalCommission = cart.reduce((prev, current) => {
    return (
      prev +
      current.price * current.quantity -
      (current.v_price - (userData?.level_data?.commission ?? 0)) *
        current.quantity
    );
  }, 0);
  useEffect(() => {
    handleLoader(true);

    AxiosController.get(`api/${slug}/${lang}/get-cart`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.length == 0) Navigate(`/${slug}/${lang}`);
        handleCart(res.data);
        setAltname(res.data[0].alt_name.split(" ")[0]);
        handleLoader(false);
      })
      .catch((e) => {});
    AxiosController.get(`api/${slug}/${lang}/get-shippingcost`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleGovs(res.data);
        handleShippingCost(res.data[0].cost);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  }, [slug, lang, token]);
  const placeOrder = () => {
    handleLoader(true);
    AxiosController.post(
      `api/${slug}/${lang}/place-order/`,
      {
        customer_name: orderClientName,
        phone_1: orderClientPhone1,
        phone_2: orderClientPhone2,
        gov_id: orderClientGov,
        public_notes: orderClientPublicNotes,
        private_notes: orderClientPrivateNotes,
        address: orderClientAddress,
        order_link: orderLink,
        zeroCOD: zeroCOD,
        COD: COD,
        reference_order: ref_order,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        Navigate(`/${slug}/${lang}/success-order`);
      })
      .catch((e) => {
        toast.error(e.response.data.error);
        handleOrderClientNameErr(
          e.response.data.error.customer_name
            ? e.response.data.error.customer_name[0]
            : ""
        );
        handleOrderClientPhone1Err(
          e.response.data.error.phone_1 ? e.response.data.error.phone_1[0] : ""
        );
        handleOrderClientGovErr(
          e.response.data.error.gov_id ? e.response.data.error.gov_id[0] : ""
        );
        handleOrderClientAddressErr(
          e.response.data.error.address ? e.response.data.error.address[0] : ""
        );
        setOrderLinkErr(
          e.response.data.error.order_link
            ? e.response.data.error.order_link[0]
            : ""
        );
        handleLoader(false);
      });
  };
  return (
    <Container style={{ position: "relative" }}>
      <Loader active={loading} />
      <Helmet>
        <title> {_("home.completeorder")}</title>
      </Helmet>
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("home.completeorder")}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <div
            className="order-right-container d-flex flex-column"
            style={{ flex: "7" }}
          >
            <div className="order-right-entry">
              <div className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4">
                <div
                  className="right-side d-flex flex-column"
                  style={{ flex: "3" }}
                >
                  <div className="right-head">
                    <h3>{_("home.customerdetails")}</h3>
                    <p>{_("home.verifydata")}</p>
                  </div>
                  {["admin", "employee"].includes(role) ? (
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {_("panel.orderLink")}
                      </label>
                      {orderLinkErr ? (
                        <Alert variant={"danger"}>{orderLinkErr}</Alert>
                      ) : (
                        ""
                      )}
                      <input
                        type="text"
                        className="form-control py-2 px-3 egp"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={orderLink}
                        onInput={(e) => setOrderLink(e.target.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {["fulfillment"].includes(role) ? (
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {_("panel.orderLink")}
                      </label>
                      {orderLinkErr ? (
                        <Alert variant={"danger"}>{ref_orderErr}</Alert>
                      ) : (
                        ""
                      )}
                      <input
                        type="text"
                        className="form-control py-2 px-3 egp"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={ref_order}
                        onInput={(e) => setRef_order(e.target.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.fullname")}
                    </label>
                    {orderClientNameErr ? (
                      <Alert variant={"danger"}>{orderClientNameErr}</Alert>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={orderClientName}
                      onInput={(e) => handleOrderClientName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.phonenumber")}
                    </label>
                    {orderClientPhone1Err ? (
                      <Alert variant={"danger"}>{orderClientPhone1Err}</Alert>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={orderClientPhone1}
                      onInput={(e) => handleOrderClientPhone1(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.phonenumber2")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={orderClientPhone2}
                      onInput={(e) => handleOrderClientPhone2(e.target.value)}
                    />
                  </div>
                  {role == "fulfillment" ? (
                    <>
                      <div className="mb-3">
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label={_("panel.zeroCOD")}
                          className="border-0"
                          value={zeroCOD}
                          onInput={(e) => {
                            setZeroCOD((value) => {
                              if (!value) {
                                setCOD(0);
                              }
                              return !value;
                            });
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          {_("home.COD")}
                        </label>
                        <input
                          type="number"
                          className="form-control py-2 px-3 egp"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={COD}
                          disabled={zeroCOD}
                          onInput={(e) => setCOD(e.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="order-right-entry">
              <div className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4">
                <div
                  className="right-side d-flex flex-column"
                  style={{ flex: "7" }}
                >
                  <div className="right-head">
                    <h3>{_("home.shippingdetails")}</h3>
                    <p>{_("home.verifydata")}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.province")}
                    </label>
                    {orderClientGovErr ? (
                      <Alert variant={"danger"}>{orderClientGovErr}</Alert>
                    ) : (
                      ""
                    )}
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        handleOrderClientGov(
                          e.target.options[e.target.selectedIndex].id
                        );
                        handleShippingCost(e.target.value);
                      }}
                    >
                      <option value={0}></option>
                      {governments.map((gov) => {
                        return (
                          <option value={gov.cost} id={gov.id}>
                            {gov.arabic_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.address")}
                    </label>
                    {orderClientAddressErr ? (
                      <Alert variant={"danger"}>{orderClientAddressErr}</Alert>
                    ) : (
                      ""
                    )}
                    <textarea
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      rows="3"
                      value={orderClientAddress}
                      onInput={(e) => handleOrderClientAddress(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.notesforclient")}
                    </label>
                    <textarea
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      rows="4"
                      value={orderClientPublicNotes}
                      onInput={(e) =>
                        handleOrderClientPublicNotes(e.target.value)
                      }
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.notesformangment")}
                    </label>
                    <textarea
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      rows="4"
                      value={orderClientPrivateNotes}
                      onInput={(e) =>
                        handleOrderClientPrivateNotes(e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="order-info d-flex flex-column gap-3"
            style={{ height: "100%", flexGrow: 1 }}
          >
            <div
              className="left-side d-flex flex-column align-items-center text-center py-4 px-4 gap-2"
              style={{ height: "100%", flexGrow: 1, width: "100%" }}
            >
              <div className="top py-3">
                <h3>{_("home.ordersummary")}</h3>
              </div>
              <div
                className="left-body py-2 d-flex flex-column gap-3"
                style={{ minWidth: "100%" }}
              >
                {cart.map((item) => {
                  return (
                    <PlaceCartItem
                      thumbnail={item.thumbnail}
                      quantity={item.quantity}
                      title={item.title}
                      price={item.price}
                    />
                  );
                })}
                <div className="pricing-info d-flex flex-column">
                  {role != "fulfillment" ? (
                    <>
                      <div className="price-row d-flex justify-content-between p-3">
                        <p
                          className="fs-5"
                          style={{ color: "rgba(23, 43, 77, 1)" }}
                        >
                          {_("home.shippingcost")}
                        </p>
                        <p>
                          {shippingcost} {altName}
                        </p>
                      </div>
                      <div className="price-row d-flex justify-content-between p-3">
                        <p
                          className="fs-5"
                          style={{ color: "rgba(23, 43, 77, 1)" }}
                        >
                          {_("home.tax")}
                        </p>
                        <p>0 {altName}</p>
                      </div>
                      <div className="price-row d-flex justify-content-between p-3">
                        <p
                          className="fs-5"
                          style={{ color: "rgba(23, 43, 77, 1)" }}
                        >
                          {_("home.commission")}
                        </p>
                        <p>
                          {totalCommission} {altName}
                        </p>
                      </div>
                      <div className="price-row d-flex justify-content-between p-3">
                        <p
                          className="fs-5"
                          style={{ color: "rgba(23, 43, 77, 1)" }}
                        >
                          {_("home.price")}
                        </p>
                        <p>
                          {totalPrice - totalCommission} {altName}
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="price-row d-flex justify-content-between p-3">
                    <p
                      className="fs-5"
                      style={{ color: "rgba(23, 43, 77, 1)" }}
                    >
                      {_("home.totalprice")}
                    </p>
                    <p>
                      {role == "fulfillment"
                        ? COD
                        : totalPrice + parseInt(shippingcost)}{" "}
                      {altName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Button
                style={{
                  backgroundColor: "rgba(89, 198, 201, 1)",
                  color: "white",
                  border: "none",
                  width: "100%",
                }}
                className="fs-5"
                onClick={() => {
                  placeOrder();
                }}
              >
                {_("panel.addorder")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PlaceOrder;
