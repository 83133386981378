import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import IconsGen from "../../../icons_generator/IconsGen";
import AxiosController from "../../../authentication/AxiosController";
import { notifyError } from "../../../toastify/toastify";
import { Button, Modal } from "react-bootstrap";

const SuppliersTableRow = ({ payments_list , get_warehouses }) => {
  const { slug, lang } = useParams();
  const { t } = useTranslation("global");
  const token = localStorage.getItem("token");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [formData, setFormData] = useState({
    fulfillment_price: "",
    confirmation_price: "",
    cancellation_price: "",
  });

  const handleEditClick = (order) => {
    setSelectedOrder(order);
    setFormData({
      fulfillment_price: order.fulfillment_price || "",
      confirmation_price: order.confirmation_price || "",
      cancellation_price: order.cancellation_price || "",
    });
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (!selectedOrder) return;
  
    try {
      const response = await AxiosController.post(
        `api/${slug}/${lang}/fulfillments/new`,
        {
          user_id: selectedOrder.seller_id,
          ...formData,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      console.log("Updated Order Data:", response.data);
      setIsModalOpen(false);
  
      // Fetch updated warehouse data after successful update
      get_warehouses();
  
    } catch (e) {
      if (e.response?.data?.error) {
        Object.entries(e.response.data.error).forEach(([key, value]) => {
          Array.isArray(value)
            ? value.forEach((err) => notifyError(`${key}: ${err}`))
            : notifyError(`${key}: ${value}`);
        });
      } else {
        notifyError("An unexpected error occurred.");
      }
    }
  };
  

  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            const dateSegments = order?.created_at_formatted.split(" ");
            return (
              <tr key={order?.id}>
                <td className="align-middle">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td className="align-middle">
                  <span className="fw-bold">{order?.user.id} #</span>
                  <br /> {order?.user.first_name} {order?.user.last_name}
                </td>
                <td className="align-middle">{order?.fulfillment_price}</td>
                <td className="align-middle">{order?.confirmation_price}</td>
                <td className="align-middle">{order?.cancellation_price}</td>
                <td className="align-middle">
                  <span
                    onClick={() => handleEditClick(order)}
                    style={{ cursor: "pointer" }}
                  >
                    <IconsGen icon_name="enter" />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">{t("panel.update")}</Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          <div className="mb-3">
            <label htmlFor="fulfillment_price" className="form-label">
              {t("panel.fulfillmentprofit")}
            </label>
            <input
              type="text"
              id="fulfillment_price"
              className="form-control py-2"
              name="fulfillment_price"
              value={formData.fulfillment_price}
              onChange={handleInputChange}
              placeholder={t("panel.fulfillmentprofit")}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="confirmation_price" className="form-label">
              {t("panel.confirmation_price")}
            </label>
            <input
              type="text"
              id="confirmation_price"
              className="form-control py-2"
              name="confirmation_price"
              value={formData.confirmation_price}
              onChange={handleInputChange}
              placeholder={t("panel.confirmation_price")}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="cancellation_price" className="form-label">
              {t("panel.cancellationprofit")}
            </label>
            <input
              type="text"
              id="cancellation_price"
              className="form-control py-2"
              name="cancellation_price"
              value={formData.cancellation_price}
              onChange={handleInputChange}
              placeholder={t("panel.cancellationprofit")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button variant="primary" onClick={handleSave}>
            {t("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SuppliersTableRow;
