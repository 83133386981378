import React, { useEffect, useState } from "react";
import Loader from "../../../loader/Loader";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { format } from "date-fns";

const Govs = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const [_] = useTranslation("global");
  const [loading, handleLoader] = useState(false);
  const [govsOrders, setGovsOrders] = useState([]);
  const [govsProfit, setGovsProfit] = useState([]);
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });

  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    get_couriers(apiStartDate, apiEndDate);
  };

  const get_couriers = (startDate, endDate) => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/government-orders`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
      .then((res) => {
        setGovsOrders(res.data.governments_orders);
        setGovsProfit(res.data.governments_profit);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const [sortConfig, setSortConfig] = useState({
    key: "approved_payment_requests", // Default sort by approved_payment_requests
    direction: "descending", // Default direction
  });

  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  // Function to format amount
  const formatAmount = (amount) => {
    return parseFloat(amount)?.toLocaleString();
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Function to get sort indicator
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? " 🔼" : " 🔽";
    }
    return " 🔽"; // Default indicator
  };

  // Combine govsOrders and govsProfit into a single array
  const combinedData = React.useMemo(() => {
    return govsOrders.map((order, index) => ({
      ...order,
      ...govsProfit[index],
    }));
  }, [govsOrders, govsProfit]);

  // Memoized sorted data based on the sort configuration
  const sortedData = React.useMemo(() => {
    let sortableItems = [...combinedData];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Convert string values to numbers for sorting if necessary
        if (!isNaN(aValue) && !isNaN(bValue)) {
          aValue = Number(aValue);
          bValue = Number(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [combinedData, sortConfig]);

  useEffect(() => {
    const { startDate, endDate } = state.selection;
    get_couriers(startDate, endDate);
  }, []);

  const totalDelivered = govsOrders.reduce(
    (acc, order) => acc + order.total_delivered,
    0
  );
  const totalMarketerCommission = govsProfit.reduce(
    (acc, profit) => acc + profit.marketer_commission,
    0
  );
  const totalSellerCommission = govsProfit.reduce(
    (acc, profit) => acc + profit.seller_commission,
    0
  );
  const totalSysCommission = govsProfit.reduce(
    (acc, profit) => acc + profit.sys_commission,
    0
  );
  const totalShippedCount = govsOrders.reduce(
    (acc, item) => acc + item.shipped_count,
    0
  );

  return (
    <div className="flex-grow-1 stats-component position-relative">
      <Helmet>
        <title>{_("home.cities")}</title>
      </Helmet>
      <Loader active={loading} />
      <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>{_("home.cities")}</h3>
        <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
          <DateRangePickerComponent
            state={state}
            setState={setState}
            applyDateRange={() => applyDateRange()}
          />
        </div>
      </div>
      <h2 className="text-center py-2">{_("panel.govsOrders")}</h2>
      <div className="data-table mt-4">
        <Table>
          <thead>
            <tr className="text-center" style={{ fontSize: "16px" }}>
              <th onClick={() => handleSort("arabic_name")}>
                {_("home.province")} {getSortIndicator("arabic_name")}
              </th>
              <th onClick={() => handleSort("total_delivered")}>
                {_("panel.totalorders")} {getSortIndicator("total_delivered")}
              </th>
              <th onClick={() => handleSort("shipped_count")}>
                {_("panel.shipped_orders")} {getSortIndicator("shipped_count")}
              </th>
              <th onClick={() => handleSort("marketer_commission")}>
                {_("panel.اجمالي ربحيه المسوقين")}  
                {getSortIndicator("marketer_commission")}
              </th>
              <th onClick={() => handleSort("seller_commission")}>
                {_("panel.اجمالي ربحيه التجار")}  
                {getSortIndicator("seller_commission")}
              </th>
              <th onClick={() => handleSort("sys_commission")}>
                {_("panel.sysCommission")} {getSortIndicator("sys_commission")}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((order, index) => (
              <tr
                key={index}
                className="text-center"
                style={{ fontSize: "16px" }}
              >
                <td>{order.arabic_name}</td>
                <td>{order.total_delivered?.toLocaleString()} <br/> القيمة  ({Number(order.total_price).toLocaleString()})</td>
                <td> 
                  <span className="fw-bold">
                    (
                    {(
                      (order.shipped_count / order.total_delivered) *
                      100
                    ).toFixed(1)}
                    %)  
                  </span>
                  <br />
                  {order.shipped_total_price?.toLocaleString()}
                </td>
                <td>
                  <span className="fw-bold">
                    (
                    {(
                      (order.marketer_commission / totalMarketerCommission) *
                      100
                    )?.toLocaleString()}
                    %)
                  </span>
                  <br />
                  {order.marketer_commission?.toLocaleString() || "N/A"}
                </td>
                <td>
                  <span className="fw-bold">
                    (
                    {(
                      (order.seller_commission / totalSellerCommission) *
                      100
                    )?.toLocaleString()}
                    %)
                  </span>
                  <br />
                  {order.seller_commission?.toLocaleString() || "N/A"}
                </td>
                <td>
                  <span className="fw-bold">
                    (
                    {(
                      (order.sys_commission / totalSysCommission) *
                      100
                    )?.toLocaleString()}
                    %)
                  </span>
                  <br />
                  {order.sys_commission?.toLocaleString() || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="text-center" style={{ fontSize: "16px" }}>
              <th>{_("panel.reportTotal")}</th>
              <th>{totalDelivered?.toLocaleString()}</th>
              <th>{totalShippedCount?.toLocaleString()}</th>
              <th>{totalMarketerCommission?.toLocaleString()}</th>
              <th>{totalSellerCommission?.toLocaleString()}</th>
              <th>{totalSysCommission?.toLocaleString()}</th>
            </tr>
          </tfoot>
        </Table>
      </div>
      <hr className="mt-4 mb-4" />
      <h2 className="text-center py-2">{_("panel.govsProfit")}</h2>
      <div className="chart-container row">
        <div className="col-12 col-md-4">
          <h4 className="m-0 text-center py-2">
            {_("panel.نسب ارباح المسوقين")}
          </h4>
          <Pie
            data={dataConstructor(
              govsProfit,
              "arabic_name",
              "marketer_commission"
            )}
            className="text-center"
          />
        </div>
        <div className="col-12 col-md-4">
          <h4 className="m-0 text-center py-2">
            {_("panel.نسب ارباح التجار")}
          </h4>
          <Pie
            data={dataConstructor(
              govsProfit,
              "arabic_name",
              "seller_commission"
            )}
            className="text-center"
          />
        </div>
        <div className="col-12 col-md-4">
          <h4 className="m-0 text-center py-2">
            {_("panel.نسب ارباح الشركه")}
          </h4>
          <Pie
            data={dataConstructor(govsProfit, "arabic_name", "sys_commission")}
            className="text-center"
          />
        </div>
      </div>
      <hr className="mt-4 mb-4" />
      <h2 className="text-center py-2">{_("panel.govsOrders")}</h2>

      <div className="chart-container row">
        <div className="col-2 col-5 m-auto">
          <Pie
            data={dataConstructor(govsOrders, "arabic_name")}
            className="text-center"
          />
        </div>
      </div>
    </div>
  );
};

const dataConstructor = (
  dataArray,
  labelName,
  totalAreaOrders,
  valueName = "stats"
) => {
  return {
    labels: dataArray.map((data) => data[labelName]),
    datasets: [
      {
        label: "",
        data: dataArray.map(
          (data) => data[totalAreaOrders ?? "total_delivered"]
        ),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
      },
    ],
  };
};

export default Govs;
