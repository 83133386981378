import "./Product.css";
import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Container, Dropdown, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { Breadcrumb } from "antd";
import IconsGen from "../../components/icons_generator/IconsGen";
import AxiosController from "../../components/authentication/AxiosController";
import Loader from "../../components/loader/Loader";
import ProductsSlider from "../../components/home/ProductsSlider";
import ProductSwipper from "../../components/home/ProductSwipper";
import { UserContext } from "../../components/contexts/UserContext";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const OfferItem = () => {
  const { slug, lang, id } = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { userData } = useContext(UserContext);

  const [loading, handleLoader] = useState(false);
  const role = localStorage.getItem("role") ?? "";
  const [product, handleProduct] = useState({});
  const [api, contextHolder] = notification.useNotification();

  const [productImgs, handleProductImgs] = useState([]);

  const [_] = useTranslation("global");
  const token = localStorage.getItem("token") ?? "";
  const Navigate = useNavigate();

  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  useEffect(() => {
    if (token) {
      window.scrollTo(0, 0);
      handleLoader(true);
      AxiosController.get(`apiv2/${slug}/${lang}/offers/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          exclusive: urlParams.get("exclusive"),
        },
      })
        .then((res) => {
          handleProductImgs([{ img_url: res.data[0].thumbnail }]);
          handleLoader(false);
          handleProduct(res?.data[0]);
        })
        .catch((e) => {
          handleLoader(false);
          //   Navigate(`/${slug}/${lang}/`);
        });
    } else {
      Navigate(`/ar/auth/login`);
    }
  }, [id, slug, lang]);
  const openNotification = (placement, status) => {
    api.success({
      style: { direction: lang == "ar" ? "rtl" : "ltr" },
      description: `${status}!`,
      placement,
    });
  };

  const addToCart = () => {
    AxiosController.post(
      `apiv2/${slug}/${lang}/offers/add-to-cart/${id}/`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        document.getElementById("ecomboCart").click();
      })
      .catch((e) => {
        toast.error(e.response.data.error);
      });
  };

  return (
    <Container dir={lang === "en" ? "rtl" : "ltr"}>
      {contextHolder}
      <div>
        {" "}
        <Helmet>
          <title>{product.title}</title>
          <meta name="description" content={product.description} />
        </Helmet>
      </div>
      <Breadcrumb
        style={{ direction: box_head_dir }}
        separator=">"
        className="p-4 px-0 fs-5"
        itemRender={(route, _, routes) => {
          const last = routes.indexOf(route) === routes.length - 1;
          return last ? (
            <Link style={{ color: "#eea536" }}>{route.title}</Link>
          ) : (
            <Link to={route.href} style={{ color: "#57c2c5" }}>
              {route.title}
            </Link>
          );
        }}
        items={[
          {
            title: _("panel.main"),
            href: `/${slug}/${lang}`,
          },
          {
            title: _("panel.offers2"),
            href: `/${slug}/${lang}/panel/products/all-offers`,
          },
          {
            title: product?.title,
            href: ``,
          },
        ]}
      />

      <div
        className="product-whole-container d-flex flex-column"
        style={{ position: "relative" }}
      >
        <Loader active={loading} />
        <div className="product-container row my-3 gap-2">
          <div
            className="product-info col-12 px-4 col-md-5 flex-grow-1"
            style={{ height: "fit-content" }}
          >
            <div
              className="d-flex flex-column"
              style={{ height: "100%" }}
              dir={lang === "en" ? "ltr" : "rtl"}
            >
              <div className="top d-flex justify-content-between my-3 align-items-center">
                <h1>{product.title}</h1>
                <div
                  className={` ${
                    product.is_fav === "true" ? true : false
                  } invisible`}
                  onClick={(e) => {
                    e.target.parentNode.classList.toggle("active");
                  }}
                >
                  <IconsGen
                    icon_name={"heartCart"}
                    active={product.is_fav === "true" ? true : false}
                  />
                </div>
              </div>
              {product?.products?.map((prd) => {
                return (
                  <>
                    <div className="head d-flex justify-content-between flex-column flex-lg-row gap-1">
                      <div
                        className="product-number d-flex col-6"
                        style={{ flexGrow: 1 }}
                      >
                        <p className="product-label">
                          {_("panel.productName")}
                        </p>
                        <p className="product-value">
                          {" "}
                          : {prd?.product?.title}
                        </p>
                      </div>
                    </div>
                    <div className="head d-flex justify-content-between flex-column flex-lg-row gap-1">
                      <div
                        className="product-number d-flex col-6"
                        style={{ flexGrow: 1 }}
                      >
                        <p className="product-label">{_("home.productcode")}</p>
                        <p className="product-value">
                          {" "}
                          :{" "}
                          <NavLink
                            className="ecombo-link-primary"
                            to={`/${slug}/${lang}/product/${prd?.product?.id}`}
                          >
                            {prd?.product?.id}
                          </NavLink>
                        </p>
                      </div>

                      <div
                        className="product-code d-flex col-6 justify-content-start gap-1"
                        style={{ flexGrow: 1 }}
                      >
                        <p className="product-label">
                          {_("home.productsku")}:{" "}
                        </p>
                        <p className="product-value">
                          {prd?.product?.variants[0]?.sku}
                        </p>
                      </div>
                    </div>

                    <div className="props price row">
                      <div className="col">
                        <div className="row">
                          <p className="pricing-label col">
                            {_("home.costprice")}:
                          </p>
                          <p
                            className="pricing-value col"
                            style={{ paddingRight: "10px" }}
                          >
                            {(prd.sys_modified_commission +
                              (prd.seller_modified_commission ??
                                prd?.product?.variants[0]?.seller_commision)) *
                              prd?.quantity}{" "}
                            <del>
                              (
                              {(prd?.product?.variants[0]?.seller_commision +
                                prd?.product?.variants[0]?.sys_commision) *
                                prd?.quantity}
                              )
                            </del>
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <p className="pricing-label col">
                            {_("home.quantity")} :
                          </p>
                          <p
                            className="pricing-value col"
                            style={{ paddingRight: "10px" }}
                          >
                            {prd?.quantity}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className="product-description" dir={box_head_dir}></div>
              <div className="buttons d-flex flex-column gap-2 my-5 justify-content-start">
                {["AFFILIATE", "ADMIN", "EMPLOYEE", "FULFILLMENT"].includes(
                  role.toUpperCase()
                ) ? (
                  <div className="top d-flex gap-4">
                    <button
                      onClick={addToCart}
                      className="btn"
                      style={{
                        flexGrow: 1,
                        background: "rgb(89, 198, 201)",
                        color: "white",
                      }}
                    >
                      {_("home.addtocart")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <ProductSwipper productImgs={productImgs} />
        </div>
      </div>
      <div className="d-block d-md-none">
        <div className="buttons d-flex flex-column gap-2 my-5 justify-content-start">
          {["AFFILIATE", "EMPLOYEE", "ADMIN"].includes(role.toUpperCase()) ? (
            <div className="top d-flex gap-4">
              <button
                onClick={addToCart}
                className="btn"
                style={{ flexGrow: 1 }}
              >
                {_("home.addtocart")}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Container>
  );
};

export default OfferItem;
