import React, { useContext } from "react";
import Slider from "react-slick";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import IconsGen from "../icons_generator/IconsGen";
import AxiosController from "../authentication/AxiosController";
import { Divider, notification, Space } from "antd";
import { UserContext } from "../contexts/UserContext";
import { toast } from "react-toastify";
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className={"slick-arrow"} onClick={onClick} style={nextArrowStyles}>
      <IconsGen icon_name={"nextArrow"} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={"slick-arrow"} onClick={onClick} style={prevArrowStyles}>
      <IconsGen icon_name={"prevArrow"} />
    </div>
  );
}

const OfferSlider = ({ row, altName }) => {
  const { userData } = useContext(UserContext);
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const [api, contextHolder] = notification.useNotification();

  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const settings = {
    infinite: row.length > 4 ? true : false,
    swipe: row.length > 4 ? true : false,
    slidesToShow: 5,
    slidesToScroll: row.length > 4 ? 4 : row.length,
    nextArrow: row.length > 5 ? <SampleNextArrow /> : "",
    prevArrow: row.length > 5 ? <SamplePrevArrow /> : "",
    autoplay: false,
    autoplaySpeed: 1000,
    speed: 200,
    rtl: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          nextArrow: "",
          prevArrow: "",
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: "",
          prevArrow: "",
        },
      },
    ],
  };
  const addToCart = (prodcut_id) => {
    AxiosController.post(
      `apiv2/${slug}/${lang}/offers/add-to-cart/${prodcut_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        document.getElementById("ecomboCart").click();
      })
      .catch((e) => {
        toast.error(e.response.data.error);

        // Navigate(`/${slug}/${lang}/product/${prodcut_id}`);
      });
  };
  return (
    <Container className="mt-4 slider-padding">
      {contextHolder}

      <Row dir={lang === "en" ? "rtl" : ""}>
        <Col className="d-flex flex-row-reverse justify-content-between align-items-center">
          <h2
            style={{
              color: "rgba(23, 43, 77, 1)",
              textTransform: "capitalize",
            }}
            className="fs-3"
          >
            {row[0] &&
              (lang === "en" ? row[0].english_name : row[0].arabic_name)}
          </h2>
          {row[0]?.cat_id && (
            <Link
              style={{
                color: "rgba(89, 198, 201, 1)",
                border: "1px solid rgba(89, 198, 201, 1)",
                background: "transparent",
                textDecoration: "none",
              }}
              className="p-2 rounded-3 cat-link"
              to={
                row[0]?.cat_id && `/${slug}/${lang}/category/${row[0].cat_id}`
              }
            >
              {_("home.seemore")}
            </Link>
          )}
        </Col>
      </Row>
      <Row>
        <Slider {...settings} className="mt-4" dir="ltr">
          {row.map((product) => {
            return (
              <div className="p-2" id={product.id}>
                <Card
                  className="pt-3 px-2 mr-2"
                  style={{
                    flexGrow: 1,
                    paddingRight: "100px",
                    minHeight: "100%",
                  }}
                  dir="rtl"
                >
                  <NavLink to={`/${slug}/${lang}/offer/${product.id}`}>
                    <Card.Img
                      src={product.thumbnail}
                      className="card-img-top px-1"
                      style={{ borderRadius: "10px", height: "200px" }}
                    />
                  </NavLink>
                  <Card.Body className="px-1">
                    <Card.Title className="d-flex flex-column flex-sm-column flex-md-row-reverse gap-2 justify-content-between align-items-center">
                      <div className="rating d-flex align-items-center justify-content-between flex-row-reverse">
                        <IconsGen icon_name="star" />
                        <p style={{ margin: 0 }}>4.5</p>
                      </div>
                      <p
                        className="title"
                        style={{ margin: 0, fontSize: "1.2rem" }}
                      >
                        {product.title ? product.title.slice(0, 5) + "..." : ""}
                      </p>
                    </Card.Title>
                  </Card.Body>
                  <Card.Body
                    className={`px-1 py-1 d-flex ${
                      lang === "en" ? "flex-row-reverse" : ""
                    } justify-content-between gap-5`}
                  >
                    <span>{_("panel.products")}</span>
                    <p>
                      {product.products.map((prd) => {
                        return (
                          <p className="d-block">
                            {prd?.product?.title?.slice(0, 9)}...
                          </p>
                        );
                      })}
                    </p>
                  </Card.Body>

                  <Card.Body
                    className={`px-1 py-1 pb-3 d-flex flex-column align-items-center flex-sm-column ${
                      lang === "en" ? "flex-md-row-reverse" : "flex-md-row"
                    } justify-content-between gap-2`}
                  >
                    <span
                      className="d-inline-block"
                      onClick={() => addToCart(product.id)}
                      id={product.id}
                    >
                      <button style={buttonStyles} className="my-3 addtocart">
                        {_("home.addtocart")}
                      </button>
                    </span>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </Slider>
      </Row>
    </Container>
  );
};

const nextArrowStyles = {
  background: "rgb(89, 198, 201)",
  display: "block",
  position: "absolute",
  right: "-30px",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "100%",
};
const buttonStyles = {
  border: "none",
  padding: ".5rem .76rem",
  borderRadius: "7px",
};
const prevArrowStyles = {
  background: "rgb(89, 198, 201)",
  display: "block",
  position: "absolute",
  left: "-30px",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "100%",
};

export default OfferSlider;
