import React, { useEffect, useState } from "react";
import "./FallingImages.css";

const FallingImages = ({ imageSrc }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const generateImages = () => {
      const newImages = Array.from({ length: 15 }).map((_, index) => ({
        id: index,
        left: Math.random() * window.innerWidth,
        size: Math.random() * 40 + 30,
        duration: Math.random() * 5 + 7,
      }));
      setImages(newImages);
    };

    generateImages();
  }, []);

  return (
    <div className="falling-container">
      {images.map(({ id, left, size, duration }) => (
        <img
          key={id}
          src={imageSrc}
          className="falling-image"
          style={{
            left: `${left}px`,
            width: `${size}px`,
            height: `${size}px`,
            animationDuration: `${duration}s`,
          }}
          alt="falling"
        />
      ))}
    </div>
  );
};

export default FallingImages;
