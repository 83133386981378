import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen"; 
const Statistics = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/panel/statistics/")) {
      setIsSubNavVisible(true);
    } else {
      setIsSubNavVisible(false);
    }
  }, [location.pathname]);

  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  return (
    <div className="links-container d-flex flex-column">
              
      <div
        className={`feature_link ${
          lang === "en" ? "feature_link_en" : ""
        } d-flex flex-row-reverse align-items-center gap-2 py-3        
        ${isSubNavVisible ? "active-link" : ""}`}
        dir={direction}
        onClick={handleClick}
      >
        <IconsGen icon_name={"statistics"} />
        <p>{_("panel.statistics")}</p>
      </div>
      {isSubNavVisible && (
        <>
          <ul
            className={`sub-nav flex-column gap-2 px-5 pt-3  ${
              isSubNavVisible ? "d-flex" : "d-none"
            }`}
          >
            {role === "employee" && employee_type === "operation employee" && (
              <>  <li>
              <NavLink to={`/${slug}/${lang}/panel/statistics/couriers`}>
                <p className="d-inline ">{_("panel.couriers")}</p>
              </NavLink>
            </li> 
              <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/orders-statuses`}
                  >
                    <p className="d-inline ">{_("panel.ordersStatus")}</p>
                  </NavLink>
                </li>
              
              </>
           
            )}

            {role === "admin" && (
              <>
                <li>
            <NavLink to={`/${slug}/${lang}/panel/statistics/couriers`}>
              <p className="d-inline ">{_("panel.couriers")}</p>
            </NavLink>
          </li>
                <li>
                  <NavLink to={`/${slug}/${lang}/panel/statistics/govs`}>
                    <p className="d-inline ">{_("home.cities")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/expenses-reports`}
                  >
                    <p className="d-inline ">{_("panel.expenses")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/earnings-reports`}
                  >
                    <p className="d-inline ">{_("panel.earningsreports")}</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={`/${slug}/${lang}/panel/statistics/all-orders`}>
                    <p className="d-inline ">{_("panel.orders")}</p>
                  </NavLink>
                </li>
         

                <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/most-frequent-buyers`}
                  >
                    <p className="d-inline ">{_("panel.mostFrequentBuyers")}</p>
                  </NavLink>
                </li>
                         <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/sellers-overview`}
                  >
                    <p className="d-inline ">{_("panel.sellersOverview")}</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/payment-request`}
                  >
                    <p className="d-inline ">{_("panel.paymentRequest")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/order-by-day`}
                  >
                    <p className="d-inline ">{_("panel.orderByDay")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/stagnant-products`}
                  >
                    <p className="d-inline ">{_("panel.stagnantProducts")}</p>
                  </NavLink>
                </li>
              </>
            )}            
                {(role === "admin"||( role === "employee" && employee_type === "operation employee" ))&&  
            <> <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/marketer-overview`}
                  >
                    <p className="d-inline ">{_("panel.marketerOverview")}</p>
                  </NavLink>
                </li>
          <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/products-overview`}
                  >
                    <p className="d-inline ">{_("panel.productsOverview")}</p>
                  </NavLink>
                </li>
               
                

            </>
            }
                          { (role === "admin"||  role === "employee") &&  
            <> <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/confirmation-report`}
                  >
                    <p className="d-inline ">{_("panel.confirmationReport")}</p>
                  </NavLink>
                </li> 
            </>
            }
       {role !== "employee" && role !== "admin"&& (
  <>
    <li>
      <NavLink to={`/${slug}/${lang}/panel/statistics/earnings`}>
        <p className="d-inline ">{_("panel.earningsreports")}</p>
      </NavLink>
    </li>
    <li>
      <NavLink to={`/${slug}/${lang}/panel/statistics/orders-statuses`}>
        <p className="d-inline ">{_("panel.ordersStatus")}</p>
      </NavLink>
    </li>
    <li>
      <NavLink to={`/${slug}/${lang}/panel/statistics/products`}>
        <p className="d-inline ">{_("panel.products")}</p>
      </NavLink>
    </li>
    <li>
      <NavLink to={`/${slug}/${lang}/panel/statistics/orders`}>
        <p className="d-inline ">{_("panel.orders")}</p>
      </NavLink>
    </li>
  </>
)}

            {(role === "admin" || role === "fulfillment") &&  
                <li>
                  <NavLink
                    to={`/${slug}/${lang}/panel/statistics/fulfillment-report`}
                  >
                    <p className="d-inline ">{_("panel.fulfillmentReport")}</p>
                  </NavLink>
                </li>
        }
          </ul>
        </>
      )}
    </div>
  );
};

export default Statistics;
