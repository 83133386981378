import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Chart } from "primereact/chart";

import StatisticsSearchComponent from "./search components/StatisticsSearchComponent";

const OrderByDay = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [search, handleSearch] = useState(false);
  const [sku, setSku] = useState("");
  const [productId, setProductId] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orders, setOrders] = useState([]);

  const get_order_by_day_report = () => {
    setLoading(true);
    AxiosController.get(`/api/${slug}/${lang}/reports/orders-by-days`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        product_id: productId,
        sku: sku,
      },
    })
      .then((res) => {
        setLoading(false);
        setOrders(res.data || []);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const chartData = {
    labels: orders.map((order) => order.day),
    datasets: [
      {
        label: _("panel.shipped_orders"),
        data: orders.map((order) => order.shipped_count),
        backgroundColor: "rgba(89, 198, 201, 0.6)",
        borderColor: "rgba(89, 198, 201, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: _("panel.shipped_orders"),
      },
    },
  };

  useEffect(() => {
    setSku(urlParams.get("sku") || "");
    setStartDate(urlParams.get("start_date") || "");
    setEndDate(urlParams.get("end_date") || "");
    setProductId(urlParams.get("product_id") || "");
  }, [urlParams]);

  useEffect(() => {
    get_order_by_day_report();
  }, [startDate, endDate, sku, productId]);

  return (
    <div className="flex-grow-1 stats-component" dir={box_head_dir}>
      <Helmet>
        <title>{_("panel.orderByDay")}</title>
      </Helmet>

      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <StatisticsSearchComponent type={"orderByDay"} /> : ""}
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.orderByDay")}
          </h3>
        </div>

        <div className="row">
          <Chart
            type="bar"
            data={chartData}
            options={options}
            style={{ width: "100%" }}
          />
        </div>

        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center"
            >
              <thead>
                <tr className="text-center">
                  <th>{_("panel.date")}</th>
                  <th>{_("panel.shipped_orders")}</th>
                  <th>{_("panel.deliveredOrders")}</th>
                  <th>{_("panel.marketer_commission")}</th>
                  <th>{_("panel.sys_commission")}</th>
                  <th>{_("panel.seller_commission")}</th>
                </tr>
              </thead>
              <tbody>
                {orders
                  .sort((a, b) => new Date(b.day) - new Date(a.day))
                  .map((order, index) => (
                    <tr key={index} className="text-center">
                      <td>{order?.day}</td>
                      <td>{order?.shipped_count?.toLocaleString()} <br/> القيمة ({Number(order.total_price_shipped).toLocaleString()})</td>
                      <td>{order?.delivered_count?.toLocaleString()}  <br/> القيمة ({Number(order.total_price_delivered).toLocaleString()})</td>
                      <td>{order?.marketer_commission?.toLocaleString()}</td>
                      <td>{order?.sys_commission?.toLocaleString()}</td>
                      <td>{order?.seller_commission?.toLocaleString()} </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderByDay;
