import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "../../components/static home/Slider";
import About from "../../components/static home/About";
import Why from "../../components/static home/Why";
import MarketerSolution from "../../components/static home/MarketerSolution";
import Header from "../../components/static home/Header";
import SupplierSolution from "../../components/static home/SupplierSolution";
import Where from "../../components/static home/Where";
import CommonQuestions from "../../components/static home/CommonQuestions";
import Says from "../../components/static home/Says";
import Footer from "../../components/static home/Footer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
export const StaticHome = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [_] = useTranslation("global");
  const { lang } = useParams();

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 10,
      once: true,
      easing: "ease-in-out",
    });

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div dir={lang === "en" ? "ltr" : "rtl"} style={{ overflow: "hidden" }}>
      <Helmet>
        <title>{_("static_home.title")}</title>
      </Helmet>
      <Header isSticky={isSticky} />
      <main>
        <Slider />
        <About />
        <Why />
        <MarketerSolution />
        <SupplierSolution />
        <Where />
        <CommonQuestions />
        <Says />
      </main>
      <Footer />
    </div>
  );
};
