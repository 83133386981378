import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Table } from "react-bootstrap";
import BarChartEarning from "../../../charts/BarChartEarning";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { format } from "date-fns";

const Earnings = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [data, handleData] = useState({});
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    get_earnings(apiStartDate, apiEndDate);
  };
  const get_earnings = (startDate, endDate) => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/system-earnings`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
      .then((res) => {
        handleData(res?.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const labels = [
    _("panel.total_expenses"),
    _("panel.sys_commission"),
    _("panel.seller_ecombo_commission"),
    _("panel.shipping_profit"),
    _("panel.shipping_loss"),
    _("panel.revenue_from_previous_months"),
    _("panel.fulfillmentprofit"),
  ];
  const values = [
    data?.expenses,
    data?.sys_commission,
    data?.seller_ecombo_commission,
    // data?.sys_commission_diff_shipping,
    data?.shipping_profit,
    data?.shipping_loss,
    data?.old_sys_commission,
    data?.fullfillment_commission,
  ];

  useEffect(() => {
    // get_earnings();
  }, [handleData]);

  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.earningsreports")}</title>
      </Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.earningsreports")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
            <DateRangePickerComponent
              state={state}
              setState={setState}
              applyDateRange={() => applyDateRange()}
            />
          </div>
        </div>
        <div className="row  " dir={box_head_dir}>
          <div className="col-12  gy-4  mb-4 d-flex align-items-center justify-content-center">
            <BarChartEarning data={data} />
          </div>

          <hr />
          <div className=" d-flex align-items-center justify-content-center">
            <div className="col-12  gy-4 mt-4   ">
              <div className="container  ">
                <Table>
                  <thead>
                    <tr className="text-center">
                      <th scope="col" style={{ fontSize: "16px" }}>
                        #
                      </th>

                      <th scope="col" style={{ fontSize: "16px" }}>
                        {_("panel.label")}
                      </th>
                      <th scope="col" style={{ fontSize: "16px" }}>
                        {_("panel.total_earnings")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {labels.map((label, index) => (
                      <>
                        <tr
                          key={label.id}
                          className="text-center"
                          style={{ fontSize: "16px" }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td style={{ fontSize: "16px" }}> {label}</td>
                          <td style={{ fontSize: "16px" }}>
                            {values[index]
                              ? values[index].toLocaleString()
                              : "0"}
                          </td>
                        </tr>
                      </>
                    ))}

                    <tr
                      className="fw-bold "
                      style={{ borderTop: "2px solid black" }}
                    >
                      <td> </td>
                      <td style={{ fontSize: "16px" }}>
                        {_("panel.reportTotal")}
                      </td>

                      <td className="fw-bold " style={{ fontSize: "16px" }}>
                        {(
                          data?.sys_commission -
                          data?.expenses +
                          data?.shipping_profit -
                          data?.shipping_loss +
                          data?.seller_ecombo_commission +
                          // data?.sys_commission_diff_shipping+
                          data?.fullfillment_commission +
                          data?.old_sys_commission
                        ).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
